import { CmRepo } from './repo'
import {
  RzNavAppCtx,
  RzAppCtxHolder,
  RzMakeAppCtx,
  BasCmComponent,
} from './rzcmnui/rzappctx'
import { RzClientConf } from './rzcmnui/rzclient'
import { RzSetErrHdr, rzlog, setRzLogEnabled } from './rzcmn'
import { CmoUser } from './repo/dto'
import { storage } from './rzcmnui/rzstore'
import { CM_SC_HOME, DEF_ENV } from './consts'
import { CmResEnum } from './repo/msg'

/***********************
 *
 */
export interface CmNavParam {}

export interface CmAppCtx extends RzNavAppCtx<CmNavParam, CmRepo> {
  userInfo?: CmoUser
  snsType?: string
  snsToken?: string
  snsEmail?: string
  getUserInfo: () => Promise<CmoUser | undefined>
  logout: (ctx: CmAppCtx) => Promise<void>
}

export const CmAppCtx_getEnv = (): string => {
  // let env=process.env.REACT_APP_RZENV || 'prd'
  let env = process.env.RZENV || process.env.REACT_APP_RZENV
  if (!env && process.env.NODE_ENV)
    env =
      process.env.NODE_ENV === 'production' ? 'prd'
      : process.env.NODE_ENV === 'development' ? 'dev'
      : 'stg';

  if (!env && DEF_ENV && DEF_ENV.length !== 0) env = DEF_ENV
  console.log(
    'RZENV=',
    env,
    ',proc.RZENV=',
    process.env.RZENV,
    ',node.env=',
    process.env.NODE_ENV,
    ',node.env=pro.env.REACT_APP_RZENV=',
    process.env.REACT_APP_RZENV,
  )
  return env || 'dev'
}

export const CmAppCtx_getClientConf = (): RzClientConf => {
  let devConf = {
    gqlUrl: 'http://192.168.25.30:8080/graphql',
    wsUrl: 'ws://192.168.25.30:8080/graphql',
    apiUrl: 'http://192.168.25.30:8080/',
    storeUserKey: 'userInfo',
    isWsOn: false,
    //defaultToken:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FjdGl2ZSI6dHJ1ZSwiY3JlYXRlZEJ5IjoicnphZG1pbiIsImNyZWF0ZWRBdCI6IjIwMjItMTItMjRUMDA6MDc6NDEuMTUwWiIsInVwZGF0ZWRCeSI6InJ6YWRtaW4iLCJ1cGRhdGVkQXQiOiIyMDIyLTEyLTI0VDAwOjA3OjQxLjE1MFoiLCJpZCI6MSwidXNlcm5hbWUiOiJ0ZXN0MSIsIm5hbWUiOiJ0ZXN0MSIsImVtYWlsIjoidGVzdCFAdGVzdC5jb20iLCJ0eXBlIjoiVVNFUiIsInJvbGVzIjoiVVNFUiIsImdyb3VwcyI6bnVsbCwic3RhdCI6IkFDVElWRSIsInV1aWQiOiI1YTFjOTBiMC04M2VlLTExZWQtYTdhOC04N2FjOWZlYjFjOGMiLCJpcCI6Ijo6ZmZmZjoxOTIuMTY4LjI1LjMwIiwiYWdlbnQiOiJNb3ppbGxhLzUuMCAoV2luZG93cyBOVCAxMC4wOyBXaW42NDsgeDY0KSBBcHBsZVdlYktpdC81MzcuMzYgKEtIVE1MLCBsaWtlIEdlY2tvKSBDaHJvbWUvMTA4LjAuMC4wIFNhZmFyaS81MzcuMzYiLCJpYXQiOjE2NzE5Mjk1MjUsImV4cCI6MTY3MjAxNTkyNX0.Uo5KB8qIKK60yY0IE-qZ5gV2j0sHJlgf5orqSrV32Dk'
  }

  let devConf0 = {
    gqlUrl: 'https://stg.ap.caspahealing.co.kr/graphql',
    wsUrl: 'wss://stg.ap.caspahealing.co.kr/graphql',
    apiUrl: 'https://stg.ap.caspahealing.co.kr/',
    storeUserKey: 'userInfo',
    isWsOn: false,
    //defaultToken:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FjdGl2ZSI6dHJ1ZSwiY3JlYXRlZEJ5IjoicnphZG1pbiIsImNyZWF0ZWRBdCI6IjIwMjItMTItMjRUMDA6MDc6NDEuMTUwWiIsInVwZGF0ZWRCeSI6InJ6YWRtaW4iLCJ1cGRhdGVkQXQiOiIyMDIyLTEyLTI0VDAwOjA3OjQxLjE1MFoiLCJpZCI6MSwidXNlcm5hbWUiOiJ0ZXN0MSIsIm5hbWUiOiJ0ZXN0MSIsImVtYWlsIjoidGVzdCFAdGVzdC5jb20iLCJ0eXBlIjoiVVNFUiIsInJvbGVzIjoiVVNFUiIsImdyb3VwcyI6bnVsbCwic3RhdCI6IkFDVElWRSIsInV1aWQiOiI1YTFjOTBiMC04M2VlLTExZWQtYTdhOC04N2FjOWZlYjFjOGMiLCJpcCI6Ijo6ZmZmZjoxOTIuMTY4LjI1LjMwIiwiYWdlbnQiOiJNb3ppbGxhLzUuMCAoV2luZG93cyBOVCAxMC4wOyBXaW42NDsgeDY0KSBBcHBsZVdlYktpdC81MzcuMzYgKEtIVE1MLCBsaWtlIEdlY2tvKSBDaHJvbWUvMTA4LjAuMC4wIFNhZmFyaS81MzcuMzYiLCJpYXQiOjE2NzE5Mjk1MjUsImV4cCI6MTY3MjAxNTkyNX0.Uo5KB8qIKK60yY0IE-qZ5gV2j0sHJlgf5orqSrV32Dk'
  }

  // let stgConf = {
  //   gqlUrl: 'https://caspa.rzdesk.com/api/graphql',
  //   wsUrl: 'wss://caspa.rzdesk.com/api/graphql',
  //   apiUrl: 'https://caspa.rzdesk.com/api/',
  //   storeUserKey: 'userInfo',
  //   isWsOn: false,
  //   //defaultToken:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FjdGl2ZSI6dHJ1ZSwiY3JlYXRlZEJ5IjoicnphZG1pbiIsImNyZWF0ZWRBdCI6IjIwMjItMTItMjRUMDA6MDc6NDEuMTUwWiIsInVwZGF0ZWRCeSI6InJ6YWRtaW4iLCJ1cGRhdGVkQXQiOiIyMDIyLTEyLTI0VDAwOjA3OjQxLjE1MFoiLCJpZCI6MSwidXNlcm5hbWUiOiJ0ZXN0MSIsIm5hbWUiOiJ0ZXN0MSIsImVtYWlsIjoidGVzdCFAdGVzdC5jb20iLCJ0eXBlIjoiVVNFUiIsInJvbGVzIjoiVVNFUiIsImdyb3VwcyI6bnVsbCwic3RhdCI6IkFDVElWRSIsInV1aWQiOiI1YTFjOTBiMC04M2VlLTExZWQtYTdhOC04N2FjOWZlYjFjOGMiLCJpcCI6Ijo6ZmZmZjoxOTIuMTY4LjI1LjMwIiwiYWdlbnQiOiJNb3ppbGxhLzUuMCAoV2luZG93cyBOVCAxMC4wOyBXaW42NDsgeDY0KSBBcHBsZVdlYktpdC81MzcuMzYgKEtIVE1MLCBsaWtlIEdlY2tvKSBDaHJvbWUvMTA4LjAuMC4wIFNhZmFyaS81MzcuMzYiLCJpYXQiOjE2NzE5Mjk1MjUsImV4cCI6MTY3MjAxNTkyNX0.Uo5KB8qIKK60yY0IE-qZ5gV2j0sHJlgf5orqSrV32Dk'
  // }


  let stgConf = {
    gqlUrl: 'https://stg.ap.caspahealing.co.kr/graphql',
    wsUrl: 'wss://stg.ap.caspahealing.co.kr/graphql',
    apiUrl: 'https://stg.ap.caspahealing.co.kr/',
    storeUserKey: 'userInfo',
    isWsOn: false,
    //defaultToken:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FjdGl2ZSI6dHJ1ZSwiY3JlYXRlZEJ5IjoicnphZG1pbiIsImNyZWF0ZWRBdCI6IjIwMjItMTItMjRUMDA6MDc6NDEuMTUwWiIsInVwZGF0ZWRCeSI6InJ6YWRtaW4iLCJ1cGRhdGVkQXQiOiIyMDIyLTEyLTI0VDAwOjA3OjQxLjE1MFoiLCJpZCI6MSwidXNlcm5hbWUiOiJ0ZXN0MSIsIm5hbWUiOiJ0ZXN0MSIsImVtYWlsIjoidGVzdCFAdGVzdC5jb20iLCJ0eXBlIjoiVVNFUiIsInJvbGVzIjoiVVNFUiIsImdyb3VwcyI6bnVsbCwic3RhdCI6IkFDVElWRSIsInV1aWQiOiI1YTFjOTBiMC04M2VlLTExZWQtYTdhOC04N2FjOWZlYjFjOGMiLCJpcCI6Ijo6ZmZmZjoxOTIuMTY4LjI1LjMwIiwiYWdlbnQiOiJNb3ppbGxhLzUuMCAoV2luZG93cyBOVCAxMC4wOyBXaW42NDsgeDY0KSBBcHBsZVdlYktpdC81MzcuMzYgKEtIVE1MLCBsaWtlIEdlY2tvKSBDaHJvbWUvMTA4LjAuMC4wIFNhZmFyaS81MzcuMzYiLCJpYXQiOjE2NzE5Mjk1MjUsImV4cCI6MTY3MjAxNTkyNX0.Uo5KB8qIKK60yY0IE-qZ5gV2j0sHJlgf5orqSrV32Dk'
  }

  let prdConf = {
    gqlUrl: 'https://ap.caspahealing.co.kr/graphql',
    wsUrl: 'wss://ap.caspahealing.co.kr/graphql',
    apiUrl: 'https://ap.caspahealing.co.kr/',
    storeUserKey: 'userInfo',
    isWsOn: false,
    //defaultToken:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FjdGl2ZSI6dHJ1ZSwiY3JlYXRlZEJ5IjoicnphZG1pbiIsImNyZWF0ZWRBdCI6IjIwMjItMTItMjRUMDA6MDc6NDEuMTUwWiIsInVwZGF0ZWRCeSI6InJ6YWRtaW4iLCJ1cGRhdGVkQXQiOiIyMDIyLTEyLTI0VDAwOjA3OjQxLjE1MFoiLCJpZCI6MSwidXNlcm5hbWUiOiJ0ZXN0MSIsIm5hbWUiOiJ0ZXN0MSIsImVtYWlsIjoidGVzdCFAdGVzdC5jb20iLCJ0eXBlIjoiVVNFUiIsInJvbGVzIjoiVVNFUiIsImdyb3VwcyI6bnVsbCwic3RhdCI6IkFDVElWRSIsInV1aWQiOiI1YTFjOTBiMC04M2VlLTExZWQtYTdhOC04N2FjOWZlYjFjOGMiLCJpcCI6Ijo6ZmZmZjoxOTIuMTY4LjI1LjMwIiwiYWdlbnQiOiJNb3ppbGxhLzUuMCAoV2luZG93cyBOVCAxMC4wOyBXaW42NDsgeDY0KSBBcHBsZVdlYktpdC81MzcuMzYgKEtIVE1MLCBsaWtlIEdlY2tvKSBDaHJvbWUvMTA4LjAuMC4wIFNhZmFyaS81MzcuMzYiLCJpYXQiOjE2NzE5Mjk1MjUsImV4cCI6MTY3MjAxNTkyNX0.Uo5KB8qIKK60yY0IE-qZ5gV2j0sHJlgf5orqSrV32Dk'
  }


  let rzEnv = CmAppCtx_getEnv()
  
  if((document as any).rzEnv_getEnv) {
      rzEnv=(document as any).rzEnv_getEnv();
      //alert('rzEnv='+rzEnv);
  }
  
  
  // rzEnv = 'prd'
  let conf = rzEnv === 'dev' ? devConf : rzEnv === 'stg' ? stgConf : prdConf

  if(rzEnv !=='prd') rzlog.debug('rzEnv = [', rzEnv, '],CONF= ', conf);
  if(rzEnv !== 'prd') console.log('RZENV.clientConf=', conf)
  if(rzEnv=='prd'){
      console.log=()=>{};
      setRzLogEnabled(false);
  }

  //conf  =devConf;

  //storage.removeItem("token");
  return conf
}

/***********************/
const doInitActions = async (ctx: CmAppCtx) => {
  let repo = ctx.repo

  // ctx.getUserInfo=async ()=>{
  // 	let uiJson= await repo.getAttr('userInfo')
  // 	if(!uiJson) return undefined;

  // 	let ui=JSON.parse(uiJson);
  // 	return ui as CmoUser;
  // }

  // ctx.logout= async (ctx:CmAppCtx)=>{
  // 	await ctx.repo.clearAttr('userInfo')
  // 	await ctx.repo.clearTokens();
  // 	ctx.setGlobalState({...ctx,userInfo:undefined})
  // }

  RzSetErrHdr(async (st, msg) => {
    if (st === CmResEnum.NO_LOGIN || msg === 'no login') {
      if (ctx?.logout) await ctx.logout(ctx)
      if (ctx) ctx.cleanLoginInfo(ctx)
      if (ctx)
        ctx.setGlobalState({ ...ctx, userInfo: undefined, token: undefined })

      if (ctx.navigation?.current)
        ctx.navigation.current.navigation.navigate(CM_SC_HOME)
    }
  })
} //doInit

/***********************
 *
 */

export const CmAppCtx_New = (appName: string, cmrepo: CmRepo, conf?: any) => {
  let rzEnv = CmAppCtx_getEnv()

  let apiUrl =
    rzEnv == 'dev'
      ? 'https://caspa.rzdesk.com/api/' //'http://192.168.25.30:8080/'  :
      : rzEnv == 'stg'
      ? 'https://caspa.rzdesk.com/api/'
      : 'https://caspa.com/api/'
  rzlog.debug('RzMakeAppCtx:conf=', conf)
  let tctx = {
    apiUrl: apiUrl,
    appName: appName,
    ...conf,
    conf: conf,
  } as CmAppCtx
  let nctx = RzMakeAppCtx(tctx, appName, cmrepo, doInitActions)
  //	nctx.userInfo={id:'test', username:'test1', roles:'ADMIN', gids:'111,222'}
  storage.setCtx(nctx)
  return nctx
}

/***********************
 *
 */
export const CmAppCtxHolder = RzAppCtxHolder

export class CmComponent<P = {}, S = {}> extends BasCmComponent<
  CmNavParam,
  CmRepo,
  CmAppCtx,
  P,
  S
> {
  static contextType = CmAppCtxHolder

  constructor(props: any) {
    super(props)
  }
}
