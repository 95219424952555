export const DEF_APPNAME='appname'



export const DEF_WS_URL='http://192.168.25.30:8080/graphql'
export const DEF_GQL_URL='http://192.168.25.30:8080/graphql'
export const DEF_API_URL='http://192.168.25.30:8080/'
export const FILE_URL='https://caspa.rzdesk.com/api/file/'

export const DEF_ENV='stg'
export const cmConst_getEnv=()=>{return DEF_ENV;}
/****************** */

export const CM_SC_HOME='';
export const CM_SC_LOGIN='login';

/* 23.12.05 CM_SC_TOWN_SAC 추가 townMain -> town 수정 */
// export const CM_SC_TOWN='townMain';
export const CM_SC_TOWN='town';
export const CM_SC_TOWN_SAC='town/profiles';

export const CM_SC_PROFILES='profiles';
export const CM_SC_SALES='sales';
export const CM_SC_SETTLE='settle';
export const CM_SC_SALES_SAC='sales/sac';
export const CM_SC_SETTLE_SAC='settle/sac';

export const CM_SC_WASHPODMODEL='washPodModel';
export const CM_SC_WASHPODMODEL_DETAIL='washPodModel/detail';
export const CM_SC_WASHPODMODEL_WRITE='washPodModel/write';
export const CM_SC_WASHPOD='washPod';
export const CM_SC_WASHPOD_DETAIL='washPod/detail';
export const CM_SC_COUPON='coupon';
export const CM_SC_COUPON_DETAIL='coupon/detail';
export const CM_SC_COUPON_WRITE='coupon/write';
export const CM_SC_COUPON_UPDATE='coupon/update';

export const CM_SC_COURSE='course';
export const CM_SC_COURSE_DETAIL='course/detail';
export const CM_SC_COURSE_WRITE='course/write';
export const CM_SC_COURSE_UPDATE='course/update';

export const CM_SC_WASHOPT='washOpt';
export const CM_SC_WASHOPT_DETAIL='washOpt/detail';
export const CM_SC_WASHOPT_WRITE='washOpt/write';
export const CM_SC_WASHOPT_UPDATE='washOpt/update';

export const CM_SC_REVIEWS='reviews';
export const CM_SC_NOTIES='notis';
export const CM_SC_REPORT='report'
export const CM_SC_REPORT_DETAIL='report/detail'

export const CM_SC_ALARM='alarm';

export const CM_SC_DATA='data';

export const CM_SC_CUSTOMER='customer'
export const CM_SC_CUSTOMER_DETAIL='customer/detail'
export const CM_SC_PARTNER='partner'
export const CM_SC_FAQ = 'faq'
export const CM_SC_FAQ_WRITE = 'faq/write'
 