import React, { Component, createRef } from 'react'
//import { View, Text, TextInput, TouchableOpacity, TextStyle, ViewStyle, ImageStyle, KeyboardTypeOptions } from "react-native";
import {
  View,
  Text,
  TouchableOpacity,
  TextStyle,
  ViewStyle,
  ImageStyle,
  TextInput,
  KeyboardTypeOptions,
  CheckBox,
  CheckBoxProp,
} from './react-components'

import { rzlog } from '../rzcmn/rzlog'
import { RzCmp, RzMCmp, RzTCmp, RzCmpProp, RzMCmpProp, RzTMCmp } from './rzcmp'
import { RzView } from './rzview'
import { RzImg } from './rzimg'
import { rzHasDisp, RzInProp } from './inc'
/*************/

const rzIs = rzlog.makeDefs()
export const RzTxt_setDbg = rzIs.setDbg
/*************/

/*************
 * Txt
 */
export interface RzTxtProp {
  textStyle?: TextStyle
  children?: React.ReactNode
  text?: string
  multiOn?: boolean
  className?: string
}

export class RzTxt extends RzTCmp<RzTxtProp> {
  constructor(pr?: any) {
    super(pr)
  }

  public render(): React.ReactNode {
    if (this.props.text) {
      return <Text style={this.props.textStyle}>{this.props.text}</Text>
    }
    return <Text style={this.props.textStyle}>{this.props.children}</Text>
  }
}

export interface RzChkProp extends CheckBoxProp {
  onChange?: (b?: boolean) => void
  title?: string
}
export class RzChk extends RzTCmp<RzChkProp> {
  constructor(pr?: any) {
    super(pr)
  }

  public render(): React.ReactNode {
    let pr = this.props
    return <CheckBox {...pr} />
  }
}

/***
 *
 */
export interface RzLbTxtProp extends RzTxtProp, Omit<RzMCmpProp, 'textStyle'> {
  size?: 'lg' | 'sm' | 'hd' | 'md'
  checked?: boolean
  checkIcon?: string | React.ReactNode
  checkIconStyle?: ImageStyle
  checkTitleStyle?: TextStyle
}

export class RzLbTxt extends RzTMCmp<RzLbTxtProp> {
  protected doRenderLeftIcon(isIconStr: boolean) {
    if (isIconStr) return <RzTxt>{this.props.icon! as string}</RzTxt>
    let iconStyle = { marginRight: 8, ...this.props.iconStyle }

    if (this.props.checked === true && this.props.checkIcon) {
      let chkIconStyle = { marginRight: 8, ...this.props.checkIconStyle }
      return <RzImg style={chkIconStyle} source={this.props.checkIcon} />
    }
    return <RzImg style={iconStyle} source={this.props.icon} />
  }
  static idCnt = 0

  protected doRenderTxt(textStyle: TextStyle) {
    let key = 'rzlbtxt-' + RzLbTxt.idCnt++ // + (Math.random());

    if (rzIs.d) rzlog.debug('RzLbTxt: ', this.props.title || this.props.text)

    let defAttr = rzHasDisp ? { marginTop: 0 } : {}
    let txtSt = { ...defAttr, ...textStyle }
    if (this.props.multiOn === true && this.props.text) {
      let vs = this.props.text?.split(/\r?\n/)
      if (vs && vs.length > 0) {
        return (
          <>
            {vs.map((v, ix) => (
              <RzTxt key={key + ix} textStyle={txtSt}>
                {v}
              </RzTxt>
            ))}
          </>
        )
      }
    }

    return (
      <RzTxt key={key} textStyle={txtSt}>
        {this.props.text || ''}
      </RzTxt>
    )
  }
}

/*************
 * TxtIn
 */
export interface RzTxtInProp extends RzCmpProp, RzInProp {
  textStyle?: TextStyle
  pholder?: string
  type?: string
  text?: string
}

export class RzTxtIn extends RzTCmp<RzTxtInProp> {
  constructor(pr: any) {
    super(pr)
  }

  public render(): JSX.Element {
    let { className } = this.props
    let textStyle = this.props.textStyle || { backgroundColor: 'skyblue' }

    let styleForLineHeight = rzHasDisp ? { height: RZ_INPUT_HEIGHT } : {}

    //!!! import
    // view.style=> textinput.viewStyle,  viewStyle.textStyle=> textinput.style
    let reasignedStyles = rzHasDisp
      ? {
          viewStyle: { ...styleForLineHeight, ...this.props.style },
          style: this.props.textStyle,
        }
      : { style: { width: '100%', ...textStyle } }

    return (
      <RzView style={this.props.style} className={className}>
        <TextInput
          editable
          text={this.props.text}
          type={this.props.type}
          onChangeText={this.props.onChangeText}
          onSubmit={this.props.onSubmit}
          {...reasignedStyles}
          placeholder={this.props.pholder}
        />
      </RzView>
    )
  }
}

export interface RzLbTxtInProp extends RzMCmpProp, RzInProp {
  pholder?: string
  type?: string
  maxlength?: number
  pholderTextStyle?: TextStyle
  bottomStyle?: ViewStyle
  bottomTextStyle?: TextStyle
  multiOn?: boolean
  rval?: any
  tailUnitText?: string
  //@onChangeText?:(txt:string)=>void;
  keyboardType?: KeyboardTypeOptions
  secureTextEntry?: boolean
  defaultText?: string
  editable?: boolean
  onSubmit?: () => void
  onFocus?: (e: any) => void
  onEndEditing?: (e: any) => void
}

function toKeyType(kt: string): 'phone-pad' | 'default' {
  switch (kt) {
    case 'phone-pad':
      return 'phone-pad'
  }
  return 'default'
}

export const RZ_INPUT_HEIGHT = 56

/*
number-pad,decimal-pad,numeric,email-address,phone-pad,url

enum('off', 'username', 'password', 'email', 'name', 'tel', 
'street-address', 'postal-code', 
'cc-number', 'cc-csc', 'cc-exp', 'cc-exp-month', 'cc-exp-year')
*/
interface RzLbInTxtStat {
  text?: string
  defaultText?: string
}

export class RzLbTxtIn extends RzTMCmp<RzLbTxtInProp, RzLbInTxtStat> {
  constructor(pr?: any) {
    super(pr)
    this.state = {}
  }

  componentDidMount(): void {
    //if(this.props.defaultText!==this.state.text)
    this.setState({
      defaultText: this.props.defaultText,
      text: this.props.text,
    })
  }

  componentDidUpdate(
    prevProps: Readonly<RzLbTxtInProp>,
    prevState: Readonly<RzLbInTxtStat>,
    snapshot?: any,
  ): void {
    //if(this.props.defaultText!==prevProps.defaultText) this.setState({defaultText:this.props.defaultText});
  }

  //protected doRenderBody():React.ReactNode
  protected isPressOff() {
    return true
  }

  public blur() {
    if (this.inputRef.current) this.inputRef.current.blur()
  }
  public clear() {
    //alert('clear call1')
    if (rzIs.d) rzlog.debug('CLEAR : >>>>>>>>>>> ', this.inputRef.current)
    if (this.inputRef.current) this.inputRef.current.clear()
  }

  public setText(txt: string) {
    if (this.inputRef.current) this.inputRef.current.setState({ text: txt })
  }

  inputRef = createRef<TextInput>()

  // style(view) => outStyle > [top, main [ left, body, right  ], bottom ]
  protected doRenderBody(): React.ReactNode {
    if (rzIs.d) rzlog.debug('RzLbTxtIn.doRenderBody:')

    //let textStyle=this.props.textStyle||{backgroundColor:'lime'}
    let textStyle = this.props.textStyle
    let tpwd = this.props.type === 'pwd' ? { secureTextEntry: true } : {}
    if (this.props.secureTextEntry !== undefined)
      tpwd = { secureTextEntry: this.props.secureTextEntry }

    let tval = {}
    let rval = this.props.rval
    let id = this.props.id
    if (rval && id) {
      tval = { defaultValue: rval[id] }
    }

    if (this.props.text) {
      tval = this.props.text ? { defaultValue: this.props.text } : {}
    }

    let keyType: any =
      this.props.type && this.props.type !== 'pwd'
        ? { keyboardType: toKeyType(this.props.type) }
        : {}
    if (this.props.keyboardType)
      keyType = { keyboardType: this.props.keyboardType }

    let hdr = this.props.pholder ? { placeholder: this.props.pholder } : {}

    let dbgAttr = { backgroundColor: 'green', borderWidth: 0 }

    //!!! important=================
    // view.style=> textinput.viewStyle,  viewStyle.textStyle=> textinput.style
    let reasignedTextStyles = rzHasDisp
      ? { viewStyle: { height: RZ_INPUT_HEIGHT, ...this.props.bodyStyle } }
      : {}

    let { text, defaultText } = this.props
    if (rzIs.d)
      rzlog.debug('RzLbTxtIn.doRenderBody: txt=', text, ',dtxt=', defaultText)

    return (
      <RzView
        style={{
          marginBottom: 0,
          flex: 1,
          height: RZ_INPUT_HEIGHT,
          borderWidth: 0,
          ...this.props.bodyStyle,
        }}
      >
        <TextInput
          ref={this.inputRef}
          {...reasignedTextStyles}
          {...tval}
          {...tpwd}
          {...keyType}
          keybordType={this.props.keyboardType}
          type={this.props.type}
          multiline={this.props.multiOn}
          maxlength={this.props.maxlength}
          onFocus={this.props.onFocus}
          onEndEditing={this.props.onEndEditing}
          onChangeText={this.props.onChangeText}
          onSubmit={this.props.onSubmit}
          defaultText={defaultText}
          text={text}
          editable={this.props.editable}
          style={{ flex: 1, paddingVertical: 4, borderWidth: 0, ...textStyle }}
          {...hdr}
        />
      </RzView>
    )
  }
}

export class RzSlotTxtIn extends RzLbTxtIn {
  constructor(pr?: any) {
    super(pr)
  }
}

export class RzFlatTxtIn extends RzLbTxtIn {
  constructor(pr?: any) {
    super(pr)
  }
}

/*************
 * DateIn
 */
export class RzDateIn extends RzCmp {
  public render(): JSX.Element {
    return (
      <TouchableOpacity>
        <View></View>
      </TouchableOpacity>
    )
  }
}

export class RzLbDateIn extends RzLbTxtIn {}

/*********
 *
 */
export interface RzAddrInProp extends RzInProp {
  cdPholder?: string
  addrPholder?: string
  detailPholder?: string
}

export class RzAddrIn extends Component<RzAddrInProp> {
  render() {
    return <RzView></RzView>
  }
}

export interface RzLbAddrInProp extends RzMCmpProp, RzAddrInProp {}

export class RzLbAddrIn extends Component<RzLbAddrInProp> {
  render() {
    return <RzView></RzView>
  }
}

/*******
 *
 */
export interface RzFileInProp extends RzInProp {}

export interface RzLbFileInProp extends RzMCmpProp, RzFileInProp {}

export class RzLbFileIn extends Component<RzLbFileInProp> {
  render(): React.ReactNode {
    return <RzView></RzView>
  }
}

export const rzTrimLine = (txt?: string, lineNo?: number) => {
  if (!txt) return txt
  if (lineNo === undefined) return txt

  let cnt = 0
  let sx = 0
  let ex = txt.indexOf('\n')

  while (ex >= 0) {
    sx = ex + 1
    cnt++
    if (cnt >= lineNo) break
    //rzlog.debug("=====================",sx,',',ex,',txt=',txt.substring(0,sx))
    ex = txt.indexOf('\n', sx)
  }

  if (ex > 0) return txt.substring(0, ex)

  return txt
}

export const rzToKeywordTxt = (txt?: string, kwd?: string, clr?: TextStyle) => {
  if (!txt) return ''
  if (!kwd) return txt

  let sx = 0
  let ex = txt.indexOf(kwd, sx)
  if (sx < 0) return <RzTxt>{txt}</RzTxt>

  let klen = kwd.length
  let len = txt.length
  let txts = []
  while (ex >= 0) {
    let tx = txt.substring(sx, ex)
    txts.push(<RzTxt>{tx}</RzTxt>)

    txts.push(<RzTxt textStyle={clr}>{kwd}</RzTxt>)
    sx = ex + klen
    ex = txt.indexOf(kwd, sx)
    //rzlog.debug('Ex=',ex,',sx=',sx,',len=',len)
  } //while

  if (sx < len) {
    let tx = txt.substring(sx)
    txts.push(<RzTxt>{tx}</RzTxt>)
  }

  return <RzTxt>{txts.map((t) => t)}</RzTxt>
}

export const rzToRows = (src: any[], pairCnt: number): any[] => {
  let rows = []
  for (let i = 0; i < src.length + pairCnt - 1; i += pairCnt) {
    let row: any[] = []

    for (let j = 0; j < pairCnt; j++) {
      if (i + j >= src.length) break
      let v = src[i + j]
      row.push(v)
    }
    if (row.length > 0) rows.push(row)
  }

  return rows
}
