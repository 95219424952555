
import React, { Component, SetStateAction } from "react";
import { useRef, useState } from "react";
import { RzBoolRes, rzlog, RzRes,IsFail } from "../rzcmn/";
import { RzUiRepo } from "./rzrepo";
import { NavigationContainerRef, NavigationContaner, RouterWrapper} from './react-nav';
import { DEF_REFRESHTOKEN, DEF_TOKEN, DEF_TOKENEXPIREDAT, DEF_USERINFO } from "./inc";
import { storage } from "./rzstore";

/************* */
const rzIs=rzlog.makeDefs(true)

/************* 
 * 
 */
export type RzAppActionType = string | any
export type RzAppActionParam = any

export interface RzStateComp {
    notiStat():void;
}

export interface RzAuthToken {
    token?:string;
    refreshToken?:string;
    tokenExpiredAt?:any;
    
    id?:string;
    username?:string;
    name?:string;
    email?:string;
    imageUrl?:string;

}

export interface RzAppCtx {
    appName?:string;
    token?:string;    
    tokenExpiredAt?:Date;
    refreshToken?:string;
    conf?:any;
    userInfo?:any;
    tokenRef?:React.MutableRefObject<string|undefined>;
    setGlobalStateRef: React.MutableRefObject<React.Dispatch<SetStateAction<RzAppCtx>>|undefined>;   
    
 


    setGlobalState: <T extends RzAppCtx>(st:Partial<T>,fn?:()=>void)=>void;
    globalStateRef:React.MutableRefObject<RzAppCtx|undefined>;
    repo:RzUiRepo;
    navigation?:any;

    setNav?:(nav?:any)=>void;

    init:(ctx:RzAppCtx)=>Promise<RzBoolRes>;

    putLoginInfo(ctx:RzAppCtx,tkn:RzAuthToken):Promise<void>;
    putUserInfo(ctx:RzAppCtx,ui:any,tkn?:RzAuthToken):Promise<void>;

    cleanLoginInfo(ctx:RzAppCtx):Promise<void>;
    getMutDiff(ctx:RzAppCtx,k:string,state:any):number;
    incMutCnt(ctx:RzAppCtx, k:string):void;

}


export interface RzNavAppCtx<PR, R extends RzUiRepo> extends RzAppCtx {
     navigation : React.RefObject<NavigationContainerRef<PR>>|null;
     
     apiUrl:string;
    
    getNav():NavigationContainerRef<PR>|null;
    setNav(nav:NavigationContaner<PR>):void;
    getRepo():R|null
    goBack():void;

}






export type RzAppAction<P,R=void> = (v:P, ctx?:RzAppCtx)=>Promise<R>;


export class BasCmComponent< NVPR,REPO extends RzUiRepo,CTX extends RzNavAppCtx<NVPR,REPO>, P,S> extends  Component<P,S> implements RzStateComp {
	 
	constructor(props:any){
		super(props);
	}

	getCtx():CTX{
		let r=this.context as CTX; 
		return r;
	}

	getNav(){
		let r=this.context as CTX; 
		let navRef= r.navigation?.current;
		if(navRef?.navigation) return navRef.navigation
		return null;
	}
    
    getPathParam(): any | undefined{
        let nv=this.getNav();
        if(!nv) return undefined;        
        return nv.getCurOption();
    }

    setNav(nav : React.RefObject<NavigationContainerRef<NVPR>>){
        let r=this.context as CTX; 
        r.navigation=nav;
    }

	goApiPage(uri?:string) {
		if(!uri) return;
		
		if(!this.context) {
			rzlog.error(`goApiPage (uri=${uri}) : no contxt`)
			return;
		}
		let ctx=this.context as CTX;
		let apiUrl=ctx.conf.apiUrl

		window.location.href=apiUrl+uri;
	}
 
	getRepo():REPO {
		let r=this.context as CTX; 
		return r.repo as REPO;
	}

	notiStat(){
		this.setState({})
	}

	componentWillUnmount(){
		
		let ctx=this.context as CTX; 
		//if(ctx?.unselect) ctx.unselect(this);
	}

}//class
 
 
 


interface InitValParam {
    newDefVal:any;
    tokenRef:React.MutableRefObject<React.Dispatch<React.SetStateAction<string>>|undefined>;
    funcRef:React.MutableRefObject<React.Dispatch<any>|undefined>;
    globalStateRef:React.MutableRefObject<RzAppCtx | undefined>;
 
    repo:RzUiRepo;
    navigation:any;

    putLoginInfo?: (ctx:RzAppCtx,tkn:RzAuthToken)=>Promise<void>;
    //putUserInfo?:(ctx:RzAppCtx,ui:any,tkn:RzAuthToken)=>Promise<void>;

    cleanLoginInfo?:(ctx:RzAppCtx)=>Promise<void>;
    getMutDiff?:(ctx:RzAppCtx,k:string,state:any)=>number;
    incMutCnt?:(ctx:RzAppCtx, k:string)=>void;    
}

const makeInitVal=<T>({newDefVal,tokenRef,funcRef, globalStateRef, repo,...rest } : InitValParam, ctx?:T)=>{
    let v={
        ...  ctx?ctx:{},
        ...rest,
        ...newDefVal, 
        tokenRef:tokenRef, 
        setGlobalStateRef:funcRef,

        setGlobalState:(st:T)=>{
            //rzlog.debug('!!!!!!!!!!!!!!!!!!!!!!! setGlobalState : st=',st.pastureId);
            if(funcRef.current) funcRef.current(st);
        },
        globalStateRef:globalStateRef,
        repo:repo, 
        putLoginInfo:rzPutLoginInfo,
        putUserInfo:rzPutUserInfo,
    
        cleanLoginInfo:rzCleanLoginInfo,
        getMutDiff:rzGetMutDiff,
        incMutCnt:rzIncMutCnt,
 
    } 

    return v;
}
/**************** */
let _defCtx :any | null= null
let _defCnt=0;
let _isLoaded=false;

//@@ 
//@@ export const rzAppCtx = React.createContext({})



//@@
export const RzMakeAppCtx= <T extends RzAppCtx>(ctx: T, name:string,repo:RzUiRepo,onInit?:(ctx:T)=>void)  =>{

    //rzlog.debug("====================>>>>>>>>>>>>>>>>>>>>>>>>>> defCnt=",_defCnt++ ,',repo=',repo  )
 
    const funcRef=useRef<React.Dispatch<SetStateAction<T>>>();
    const tokenRef=useRef<React.Dispatch<SetStateAction<string>>>();
    const globalStateRef=useRef<RzAppCtx>();
    
    

    let newDefVal:any={};

    let initPr:InitValParam={ 
        newDefVal:newDefVal,
        tokenRef:tokenRef,
        funcRef:funcRef, 
        globalStateRef:globalStateRef, 
        repo:repo, 
        navigation: { current: { 
                    navigation : { 
                        navigate:(p:string, pr?:any)=>{ 
                                  window.location.href=encodeURI('#/'+p);
                         },
 
                         getCurOption:()=>{
                            var t =''+window.location.href;
                            const url = new URL(t);
                            var lpath=t.substring(t.lastIndexOf('/') + 1)
                            
                            const urlParams = { ...url.searchParams, uri:url.hash, lastPath:isNaN(Number(lpath)) ? lpath : Number(lpath)};

                            return urlParams;
                         } 
                    }} }
    }

    let initVal=makeInitVal<T>(initPr,ctx)
    const [globalState,setGlobalState]=useState<T>(initVal);
    
    funcRef.current=setGlobalState;
    globalStateRef.current=globalState;

    rzlog.debug('GLOBAL=',globalState);

    if(rzIs.d)rzlog.debug(">>>>>> reset RzMakeAppCtx : _isLoaded=",_isLoaded)
    if(onInit) onInit(globalState);

    if(!_isLoaded && !repo.isLoaded()) {
            _isLoaded=true;
            repo.load(globalState,  (v)=>{
                let nv={...globalState,...v}
                if(rzIs.d)rzlog.debug(">>>>> reset : GLOABL . setGlobalStat4e ====================================: nv=",nv)
                
                setGlobalState(nv)
            }).then( async b=>{
                    if(rzIs.d)rzlog.debug('======================== loaded, ==> ctx.init')
                    let ui= storage.getAttr(DEF_USERINFO)

                    //if(rzIs.d)
                    rzlog.debug(">>>>>> reset RzMakeAppCtx.2 : _isLoaded=",_isLoaded,',ui=',ui,'ctx.ui=',globalState.userInfo);

                    if( globalState.init) globalState.init(globalState); 
                }
            );    //Sync

      //  Async
    }

    let ui=storage.getAttr(DEF_USERINFO);
    if('string' === typeof ui)
     globalState.userInfo=JSON.parse(ui);
     
    //if(rzIs.d) 
    rzlog.debug("GLOABL . globalState =======================>>>>>>>>>>>>>>>> defCnt conf=",globalState.conf,'ui=',ui)
    _defCtx=globalState

    
    if(onInit) onInit(globalState);

    return _defCtx;
}

 
/*************************
 * 
 */

const rzPutLoginInfo=async (ctx:RzAppCtx,tkn:RzAuthToken)=>{
        await storage.setItem(DEF_TOKEN, tkn.token);
        if(tkn.refreshToken) await storage.setItem(DEF_REFRESHTOKEN, tkn.refreshToken);
        if(tkn.tokenExpiredAt) await storage.setItem(DEF_TOKENEXPIREDAT, tkn.tokenExpiredAt);
}

const rzPutUserInfo=async (ctx:RzAppCtx,userInfo:any,tkn?:RzAuthToken)=>{

    if(tkn)  { await rzPutLoginInfo(ctx,tkn) ;}

    let uiJson= JSON.stringify(userInfo);
    await storage.setItem(DEF_USERINFO, uiJson);
    ctx.setGlobalState({...ctx,userInfo:userInfo,...tkn})
}

const rzCleanLoginInfo=async (ctx:RzAppCtx)=>{
    await storage.removeItem(DEF_TOKEN);
    await storage.removeItem(DEF_USERINFO);

    ctx.setGlobalState({...ctx, 
        token:undefined,
        tokenExpiredAt:undefined,
        refreshToken:undefined,
        refreshTokenExpiredAt:undefined,
        userInfo:undefined});
}


/*************************
 * 
 */
const rzGetMutDiff=(ctx:RzAppCtx,k:string,state:any)=>{
    let sv=state[k];
    let cv=(ctx as any)[k];

    if(!cv) cv = 0;
    if(!sv) sv = 0;
    //rzlog.debug('GetMutDiff : ctx.mut=',cv,',st.mut=',sv)
    if(cv==sv) return 0;
    return cv;
}

const rzIncMutCnt=(ctx:RzAppCtx, k:string)=>{
    let cv=(ctx as any)[k];
    if(!cv) cv=0;
    
    cv+=1;
    //rzlog.debug("IncMutCnt : k=",k,", cnt=",cv);
    ctx.setGlobalState({...ctx, [k]:cv});
}

/*************************
 * 
 */
 

export  const  RzAppCtxHolder= React.createContext<RzAppCtx | undefined>(undefined)
//export  const  RzAppCtxHolder= React.createContext<React.MutableRefObject<RzAppCtx> | undefined>(undefined)


/************
 * 
 */


export class RzAppComp<R extends RzUiRepo ,CTX extends RzAppCtx, P,S={} > extends Component<P,S>   {
	static contextType=RzAppCtxHolder
	constructor(props:any){
		super(props);
	}

	getCtx():CTX{
		let r=this.context as CTX; 
		return r;
	}

	getRepo():R {
		let r=this.context as CTX; 
		return r.repo as R;
	}

}

