import { Dispatch, SetStateAction } from "react";
import { NewOk, RzBoolRes, RzRes ,rzlog} from "../rzcmn/";

import { RzAppCtx, RzStateComp } from "./rzappctx"; //
import AsyncStorage from './react-storage'
import { storage } from "./rzstore";
import { DEF_REFRESHTOKEN, DEF_TOKEN, DEF_TOKENEXPIREDAT, DEF_USERINFO } from "./inc";
/************** */
const _isDbg=false;
const rzIs=rzlog.makeDefs()

/***************
 * 
 */
export interface RzToken{
    token?:string;
    refreshToken?:string;
    expiredAt?:Date;
}

export  class  RzTokensRes extends RzRes<RzToken>{
}

export interface RzUiRepo {
    isLoaded():boolean;
    load(ctx?:any,setState?: Dispatch<SetStateAction<any>>):Promise<boolean>

    hasToken():boolean;
    getTokens(): Promise<RzTokensRes>
    saveTokens(tk:string, expiredAt?:Date, refreshTkn?:string) : Promise<RzBoolRes>;
    clearTokens(): Promise<RzBoolRes>;

    setAttr(k:string, v:any): Promise<void>;
    getAttr(k:string): Promise<any|null>;
    clearAttr(k:string): Promise<void>;

    select : (fldNm:string, obj:any, comp:RzStateComp)=>void
    unselect:(comp:RzStateComp)=>void;
}




export class RzBasUiRepo implements RzUiRepo {
    
    ctx:RzAppCtx|null=null;

    setCtx(ctx:any){
        this.ctx=ctx;
    }
    
    async setAttr(k: string, v: string): Promise<void> {
          storage.setItem(k,v)
        if(rzIs.d) rzlog.debug('>>>>>> reset :  setAttr=',k,',v=',v)
    }

    async getAttr(k: string): Promise<string|any|null> {
        
        let r= await storage.getItem(k)

        return r||null;
    }
    
    async clearAttr(k: string): Promise<void> {
       await storage.removeItem(k)
    }

 

    token?:string;
    tokenExpiredAt?:Date;
    refreshToken?:string;
    _isLoaded:boolean=false;
    

    public select= (fldNm: string, obj: any, comp: RzStateComp) => {};
    public unselect=(comp:RzStateComp)=>{}

    public hasToken(): boolean {
        return this.token!==undefined;
    }

    isDefOn=true
    callRepo=<P,Q,R>( rp:P, rq?:Q , fn?:(rp:P, rq?:Q)=>Promise<R>,  def?:R, isDef?:boolean ) :  Promise<R> => {
        //rzlog.debug('=============findRecentNotis: def isDefON=',this.isDefOn, '')
        if(this.isDefOn && isDef && def) return new Promise((res)=>{res(def)});
        
        return fn!(rp,rq)
    }


    async getTokens(): Promise<RzTokensRes> {
        let tks={token:this.token, tokenExpiredAt:this.tokenExpiredAt , refreshToken:this.refreshToken}
        return NewOk(tks)
    }

    async clearTokens(): Promise<RzBoolRes> {
        this.token=undefined;
        this.tokenExpiredAt=undefined;
        this.refreshToken=undefined;
        
         storage.removeItem(DEF_TOKEN)
         storage.removeItem(DEF_TOKENEXPIREDAT)
         storage.removeItem(DEF_REFRESHTOKEN)

        return NewOk(true)
    }

    isLoaded(): boolean {
        return this._isLoaded;
    }

    async load(ctx?: RzAppCtx,setState?: Dispatch<SetStateAction<any>>): Promise<boolean> {
      
        await this.loadTokens(ctx,setState)
        this._isLoaded=true;

        return true;
    }

    async loadTokens(ctx?: RzAppCtx,setState?: Dispatch<SetStateAction<any>>):Promise<RzBoolRes>{

        let tkn= await storage.getItem(DEF_TOKEN)
        let tknExp=await storage.getItem(DEF_TOKENEXPIREDAT)
        let rtkn=await storage.getItem(DEF_REFRESHTOKEN)
        let uinfo=await storage.getItem(DEF_USERINFO)

        //rzlog.debug('>>>>>>>>> reset : loadTokens - uinfo=',uinfo,',tkn=',tkn);


        if(tkn)  this.token=tkn;
        if(tknExp) this.tokenExpiredAt= new Date(tknExp);
        if(rtkn) this.refreshToken=rtkn;

        if(ctx) {
            //alert(''+ typeof uinfo)
            if('string'=== typeof uinfo) ctx.userInfo= JSON.parse(uinfo);
            else ctx.userInfo= uinfo;
            storage.setAttr(DEF_USERINFO,uinfo);

            ctx.token=tkn;
            storage.setAttr(DEF_TOKEN,tkn);
        }

        // if(setState){
        //     //rzlog.debug('Set Token : ====================================== : TKN!!!!!!!!!!!!!!!!!!!!=',tkn)
        //     setState({token:tkn})
        //     if(ctx?.tokenRef) ctx.tokenRef.current=tkn!;
        // }

      
        return NewOk(true);
    }
    
    async saveTokens(token : string,expiredAt?:Date,refresh?:string) : Promise<RzBoolRes>{
        await storage.setItem(DEF_TOKEN,token)
        if(expiredAt) await storage.setItem(DEF_TOKENEXPIREDAT,expiredAt.toISOString())

        if(refresh) await storage.setItem(DEF_REFRESHTOKEN,refresh)
        
        return NewOk(true);
    }

 
}//class



