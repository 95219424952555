

/***
 * 
 */

import { rzlog } from "./inc";


export const rz_toTimeCountFmt=(count:number):string=>{
	let hv=(count/60).toFixed(0);
	let lv=(count%60).toFixed(0);
	return hv+":"+lv;
}

export function rzLeftPad(value:number) {
	if (value >= 10) {
		return value;
	}

	return `0${value}`;
}
export const rzUiFormatDate=(dt?:Date|string,fmt?:string):string=>{
	if( !Boolean(dt) )return '';
	return rzToDateFormat(fmt||'YY-MM-DD',dt)
}

export const rzToCommaNum=(dt?:number,fx?:number)=>{
    if(!dt) return '0';
    if(!fx) dt=parseInt(dt.toFixed());
    
    return dt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const zero = (value: number | string) => value.toString().length === 1 ? `0${value}` : value;

type DateType = Date | string | number;

export const  rzToDateFormat = (format: string, date: DateType = Date.now()): string => {
    const allowForm = date;
    const _date = new Date(allowForm);

    return format.replace(/(yyyy|mm|dd|YY|MM|DD|H|i|s)/g, (t: string): any => {
        switch (t) {
            case "yyyy":
                return _date.getFullYear();
			case "YY":
				return zero( (_date.getFullYear()%100).toFixed(0));
            case "mm":
                return _date.getMonth() + 1;
            case "dd":
                return _date.getDate();
            case "MM":
                return zero(_date.getMonth() + 1);
            case "DD":
                return zero(_date.getDate());
            case "H":
                return zero(_date.getHours());
            case "i":
                return zero(_date.getMinutes());
            case "s":
                return zero(_date.getSeconds());
            default:
                return "";
        }
    });
};

export const  rzDateRange = (type: string): {} => {
    const _date = new Date(Date.now());
    let newDate = {};
    let firstDayOfMonth = new Date();
    let lastDayOfMonth = new Date();

    switch (type) {
        case "all":
            newDate = {};
            return newDate;
        case "month":
            firstDayOfMonth = new Date(_date.getFullYear(), _date.getMonth(), 1);
            newDate = {start:firstDayOfMonth, end:lastDayOfMonth};
            return newDate;
        case "week":
            firstDayOfMonth = new Date(Date.now() - 1000 * 3600 * 24 * 7);
            newDate = {start:firstDayOfMonth, end:lastDayOfMonth};
            return newDate;
        default:
            return {start:new Date(), end:new Date()};
    }
};
 
 

// Date.prototype.format = function(f) {
//     if (!this.valueOf()) return " ";
 
//     var weekName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
//     var d = this;
     
//     return f.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, function($1) {
//         switch ($1) {
//             case "yyyy": return d.getFullYear();
//             case "yy": return (d.getFullYear() % 1000).zf(2);
//             case "MM": return (d.getMonth() + 1).zf(2);
//             case "dd": return d.getDate().zf(2);
//             case "E": return weekName[d.getDay()];
//             case "HH": return d.getHours().zf(2);
//             case "hh": return ((h = d.getHours() % 12) ? h : 12).zf(2);
//             case "mm": return d.getMinutes().zf(2);
//             case "ss": return d.getSeconds().zf(2);
//             case "a/p": return d.getHours() < 12 ? "오전" : "오후";
//             default: return $1;
//         }
//     });
// };
 
 