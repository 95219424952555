import React, { Component } from 'react';
//@ import {View, Text, ViewStyle, TextStyle, StyleProp, Button,
//     Image, ImageSourcePropType, ImageStyle, TouchableNativeFeedback, 
//     TouchableWithoutFeedbackProps, TouchableOpacity, TouchableHighlight, Modal, StyleSheet } from "react-native";

import {View, Text, ViewStyle, TextStyle, 
    Image, ImageSourcePropType, RNPickerSelect,
     TouchableOpacity,
     Select,  } from "./react-components";

import { rzHasDisp, RzInProp, rzlog } from './inc';


import { RzBtn, RzLbBtn } from './rzbtn';
import {RzTxt} from './rztxt';
import { RzMCmp, RzMCmpProp ,RzTMCmp} from './rzcmp';
import { RzCol, RzPageCtx, RzRow, RzView } from './rzview';
import { toStyles } from './rzstyles';
import { IC_ARROW_DOWN, IC_OK } from '../assets';


/***************/
const rzIs=rzlog.makeDefs();

export interface RzSelItem{
    label:string;
    value:any;
}



/***********
 * RzSel
 */

 export interface RzSelProp extends RzInProp {
    style?:ViewStyle;
    textStyle?:TextStyle;
    boxStyle?:ViewStyle;
    icon?:React.ReactNode;
    pholder?:string;
    items?:RzSelItem[];
    selected?:any;
    outline?:boolean;
    onChange?:(v:any,ix?:number)=>void;
}

export class RzSel extends Component<RzSelProp> { 
    constructor(pr:any){
        super(pr)
    }

	public render(): React.ReactNode { 
        let{className}=this.props;
        let st={borderWidth:0,...this.props.style}
        let nitems= this.props.items ;

         //rzlog.debug('============== seldStyle>>> st=',this.props.boxStyle)
        return ( 
                    <Select textStyle={this.props.textStyle} className={className} style={st} outline={this.props.outline}
                            onChange={this.props.onChange} size={this.props.size}
                         items={nitems} selected={this.props.selected} placeholder={this.props.pholder} />
            )
        // return (<RzLbBtn  style={this.props.style} 
        //             text="text" title="ttt" />);
	}
}

export interface RzSelItem {
    id: any;
    title:string;
    data?:any;
}

/*************
 * 
 */
export interface RzLbSelProp extends RzMCmpProp , Omit<RzSelProp,'icon'> {
    items?:RzSelItem[];
    selIcon?:ImageSourcePropType;
    itemStyle?:ViewStyle;
    inner?:boolean;

    popupStyle?:ViewStyle;
    popupInStyle?:ViewStyle;
    onSelected?:(it:RzSelItem,ix:number)=>void
    selectedItem?:RzSelItem;
    
}

interface State {
    isPopup:boolean;
    selectedItem?:RzSelItem;
    isInnerPopup:boolean;
    topPos:number;
}


const st_selBox=toStyles(`
border-width:1px;
border-color:#CBCBCB;
border-radius:5px;
justify-content:space-between;
align-items:center;
height:56px;
padding-left:15px;
padding-right:20px;
flex-direction:row;
flex:1;
`)

/***************************** */

export class RzLbSel extends RzTMCmp<RzLbSelProp,State> { 

    constructor(props:RzLbSelProp){
        super(props)
        this.state={
            isPopup:false,
            isInnerPopup:false,
            topPos:0,
        }
    }

    onPress=(e:any)=>{
        if(rzIs.d) rzlog.debug("RzLbSel.Pressed:e=",e)
        this.setState({isPopup:!this.state.isPopup})
    }
    static contextType = RzPageCtx;
    protected doRenderBody(): React.ReactNode {
       //rzlog.debug("=======================================RzSel.render :ctx=",rzHasDisp)
        if(rzHasDisp) return this.doRenderBody4React();
        else return this.doRenderBody4RN();
     }

     doRenderBody4React(){
        const selText= this.state.selectedItem?.title || this.props.selectedItem?.title|| this.props.text||'선택'

        //rzlog.debug("=======================================RzSel.render :ctx=",popupAttr)
        //this.context.setPopupAttr({popupAttr:{visible:true}})
        const selItem=this.props.selectedItem||this.props.selected
        return (<Select style={this.props.bodyStyle} placeholder={this.props.pholder} 
                    onChange={(v,ix)=> { 
                        if(rzIs.d) rzlog.debug("Select.onChange : v=",v)
                        if(this.props.onSelected)  this.props.onSelected(v,ix);
                    }}
                    items={this.props.items} selected={selItem} />);

     }

     protected doRenderBody4RN(): React.ReactNode {
    
        const selText= this.state.selectedItem?.title || this.props.selectedItem?.title|| this.props.text||'선택'

        //rzlog.debug("=======================================RzSel.render :ctx=",popupAttr)
        //this.context.setPopupAttr({popupAttr:{visible:true}})

        return (<>
            <TouchableOpacity  style={{width:'100%',}} 
                    disabled={this.props.inactive}
                onPress={(e)=>{
                    //rzlog.debug("RzLbSel.Pressed:e.tgt.st.top=",e.nativeEvent.locationY)
                    this.doShowPopup(e)
                }}>
        
                <RzCol style={{height:56,width:'100%',backgroundColor:'#ffffff',...this.props.style}}>                    
                    <RzCol style={{height:56,  width:'100%',backgroundColor:'#ffffff' , ...st_selBox,}} >
                        <RzTxt>{selText}</RzTxt>
                        <Image source={this.props.selIcon||IC_ARROW_DOWN} />
                    </RzCol>
                </RzCol>
            </TouchableOpacity>
            {this.state.isInnerPopup && (
                <RzView style={{ position:'absolute', zIndex:999, top:60, width:'100%',flex:1, flexDirection:'column'}}>
                    {this.doRendList()}
                </RzView>
            )}
            </>
         );
     }


     doRendList(){
        const selText= this.state.selectedItem?.title || this.props.selectedItem?.title|| this.props.text||'선택'
        const selItem= this.state.selectedItem || this.props.selectedItem
        //marginLeft:35, marginRight:35,
        return (
            <RzView style={{flex:1,borderWidth:1, borderRadius:3,
                borderColor:'#CBCBCB',  ...this.props.popupInStyle}} >
                {this.props.items && this.props.items.map((it,ix)=>
                        this.doRenderItem(it,ix,selItem)
                )}
            </RzView>
        )
     }


     doShowPopup(e:any){

        if(this.props.inactive ) return;
        if(this.props.inner){
            this.setState({isInnerPopup:!this.state.isInnerPopup,
                topPos:e.nativeEvent.pageY-e.nativeEvent.locationY-30})
           return;
        }

        const setPopupAttr=(this.context as any).setPopupAttr;
        const popupAttr=(this.context as any).popupAttr
        setPopupAttr({
               visible:!popupAttr.visible,
               style:{ //position:'absolute', zIndex:9999, top:100,
                       top:e.nativeEvent.pageY-e.nativeEvent.locationY-30,
                       width:'100%', backgroundColor:'white', ...this.props.popupStyle},
               popup:()=>{ return this.doRendList()}
               //popup:this.doRendList()
       })
     }

     static idCnt=0

     doRenderItem(it:RzSelItem, ix:number,selected?:RzSelItem){
        const defStyles={paddingLeft:10,borderWidth:0,  borderTopWidth:0, borderColor:'#CBCBCB'}
        const setPopupAttr=(this.context as any).setPopupAttr;
        const isSelected=(selected===it)
        //rzlog.debug('=========================sel=',selected, ',it=',it)
        const styles:ViewStyle= { ...{height:48, width:'100%', borderWidth:1, alignItems:'center'},
                ...defStyles, ...this.props.itemStyle,
                backgroundColor: isSelected?'#F0F6FF':'white'
            }

        const tkey='rlst-'+RzLbSel.idCnt++;

        return (
            <TouchableOpacity key={tkey+'-'+ix} 
                style={ { borderWidth:0,width:'100%', backgroundColor: isSelected?'#F0F6FF':'white'}} 
                onPress={()=>{ 
                    if(this.props.onSelected) this.props.onSelected(it,ix);

                    if(this.props.inner) this.setState({isInnerPopup:false});
                    else setPopupAttr({visible:false});
                }}>

                <RzRow style={styles} key={''+ix}>
                    <Text>{it.title}</Text>
                </RzRow>
            </TouchableOpacity>
        )
    }     

 

}//class




/*******
 * RzPick
 */

 export interface RzPickProp  {
    style?:ViewStyle;
    textStyle?:TextStyle;
    boxStyle?:ViewStyle;
    icon?:React.ReactNode;
    items?:RzSelItem[];
    selected?:any;
    onChange?:(v:any,ix:number)=>void;
}

export class RzPick extends Component<RzPickProp> { 
	public render(): React.ReactNode { 
		if(rzIs.d) rzlog.debug('RzBtn.pros.style=',this.props.style,',textStyle=',this.props.textStyle)
        return (<View style={this.props.style}><RNPickerSelect 
            style={{chevron:{borderWidth:0}}}
            onValueChange={(v:any,ix:number)=>{ 
                if(this.props.onChange) this.props.onChange(v,ix);
            } }
            
            items={this.props.items||[]}
            /></View>);
	}
}