//import { rzlog } from "src/rzcmn";
import { RzSelItem } from "../rzcmnui";

import {CmRepo4Mem }  from "./cmrepo.mem";
import { rzlog } from "../rzcmn";
import { CmoShop, CmoWashCourse } from "./dto";
export  *  from "./cmrepo";
export * from "./defs" 

const rzIs=rzlog.makeDefs()
export const CmRepo_setDbg=rzIs.setDbg;

export let cmrepo : CmRepo4Mem|null=null;
export const CmRepo_getRepo=(conf?:any)=>{
    if(!cmrepo) cmrepo= new CmRepo4Mem(conf);
    if(rzIs.d) rzlog.debug('CmRepo_getRepo : repo=',cmrepo)
    return cmrepo;
}



//opts ='a=1,b=2;a=2,b=3;'
export const cmToSelItems=(opts:string):RzSelItem[]=>{
    let vs=opts.split(';');
    let nvs:RzSelItem[]=[] ;
   
   
    for(let j in vs) {
        let it:any={} 
        let jvs=vs[j];
        let tvs= jvs.split(',');
        for(let i in tvs){
          
            let t=tvs[i]
            if(i==='0'){
                let ix=t.indexOf('=');
                let k=t.substring(0,ix).trim();
                let kv=t.substring(ix+1).trim();
                it['id']=k;
                it['title']=kv;
            } else {
                let ix=t.indexOf('=');
                let k=t.substring(0,ix).trim();
                let kv=t.substring(ix+1).trim();
                it[k]=kv;
            }
        }
        nvs.push(it as RzSelItem);
    }
      
     return nvs;
}

export const cmToSelItemsByObj=(opts?:any[]):RzSelItem[]=>{
    if(!opts) return [];

    let vs=opts;
    let nvs:RzSelItem[]=[] ;
   
   
    for(let j in vs) {
        
        let t=vs[j];
        let it:RzSelItem={...t} 
        nvs.push(it as RzSelItem);
    }
      
     return nvs;
}



export interface CalcAmount {
    payAmount:number
}
export const cmCalcAmount=(shop?:CmoShop, course?:RzSelItem, opts?:RzSelItem[]):CalcAmount=>{
    let payAmt=0;
    if(course){
        let cs=course as any
        payAmt=1000;
        if(cs.amount) payAmt=parseInt(cs.amount);
        if(cs.price) payAmt=parseInt(cs.price);
    }

    if(opts){
        for(let i in opts){
            let v=opts[i] as any;

            
            if(v.price){
              let tv  =parseInt(v.price);
              payAmt+=tv;
            } else payAmt+=1;
        }
    }
    
    return {payAmount:payAmt};

}



export const cmMakeFileUrl=(id?:any,ctx?:any)=>{
	let fileUrl=ctx?.conf?.apiUrl+'file/';
	return `${fileUrl}${id}`
  }
