 
import {RzFindReq, RzMutReq, RzReq, RzRes, RzRsRes} from '../rzcmn';
import {
  CmoAuthSmsToken,
  CmoAuthToken,
  CmoBbsArticle,
  CmoBbsReply,
  CmoWashPod,
  CmoUser,
  CmoUserReg,
  CmoWeather,
  CmoRsv,
  CmoShop,
  CmoWash,
  CmoPay,
  CmoCpn,
  CmoCpnModel,
  CmoGift,
  CmoGiftModel,
  CmoCar,
  CmoNoti,
  CmoCreditCard,
  CmoLoc,
  BbsFavorIn,
  CmoCarAddOn,
  CmoReview,  
  CmoShopEquipIn,
  CmoShopEquip,
  CmoWashCourse,
  CmoWashOpt,
  CmoSettle,
  CmoSettleRow,
  CmoPrepaidCash,
  CmoStat,
  CmoShopInfo,
  CmoFileRef,
  CmoBbsAttach
} from './dto';


export class CmResEnum{
    public static UNKNOWN=1;
    public static NO_LOGIN=2;
    public static NO_EXIST=3;
}

export class LoginReq {
  username?: string;
  password?: string;

  type?: string;
  token?: string;

  etcType?: string;
  etcData?: string;
}
export class LoginRes extends RzRes<CmoAuthToken> {}
export class LogoutReq {}
export class LogoutRes {}

export class AuthSmsReq {
  phone?: string;
  phoneAuthCode?: string;
}

export class AuthSmsRes extends RzRes<CmoAuthSmsToken> {}

export class UserDupReq {
  username?: string;
  isAllowed?: boolean;
}

export class UserRegReq extends RzReq<CmoUserReg> {}

// export class FileRefReq {
//   data?:CmoFileRef;
// }
export class FileRefReq extends RzReq<CmoFileRef>{}
export class FileRefRes extends RzRes<CmoFileRef> {
  
}

export class RzFileReq extends RzReq<CmoFileRef>{}
export class RzFileRes extends RzRes<CmoFileRef> {
  
}

/***************** */
export class UserReq extends RzReq<CmoUser> {}
export class UserRes extends RzRes<CmoUser> {}

/**************
 * Article
 */
export class BbsArticleFindReq extends RzFindReq<CmoBbsArticle> {}
export class BbsArticleReq extends RzReq<CmoBbsArticle> {}
export class BbsArticleMutReq extends RzMutReq<CmoBbsArticle> {}
export class BbsArticleRes extends RzRes<CmoBbsArticle> {}
export class BbsArticleRsRes extends RzRsRes<CmoBbsArticle> {}


/**************
 * Attach
 */
export class BbsAttachReq extends RzMutReq<CmoBbsAttach> {}
export class BbsAttachRes extends RzRes<CmoBbsAttach> {}

// favor
export class BbsFavorReq extends RzReq<BbsFavorIn> {}
export class BbsFavorRes extends RzRes<BbsFavorIn> {}

// reply =============
export class BbsReplyFindReq extends RzFindReq<CmoBbsReply> {}
export class BbsReplyReq extends RzReq<CmoBbsReply> {}
export class BbsReplyMutReq extends RzMutReq<CmoBbsReply> {}
export class BbsReplyRes extends RzRes<CmoBbsReply> {}
export class BbsReplyRsRes extends RzRsRes<CmoBbsReply> {}
// noti ===============
export class CmNotiFindReq extends RzFindReq<CmoNoti> {}
export class CmNotiReq extends RzReq<CmoNoti> {}

export class CmNotiRes extends RzRes<CmoNoti> {}
export class CmNotiRsRes extends RzRsRes<CmoNoti> {}
// ===============
export class WeatherReq extends RzReq<CmoWeather> {}
export class WeatherRes extends RzRes<CmoWeather> {}
export class WeatherFindReq extends RzFindReq<CmoWeather> {}
export class WeatherRsRes extends RzRsRes<CmoWeather> {}

/**************
 * Wash
 */
export class CmWashFindReq extends RzFindReq<CmoWash> {}
export class CmWashRsRes extends RzRsRes<CmoWash> {}
export class CmWashReq extends RzReq<CmoWash> {}
export class CmWashMutReq extends RzReq<CmoWash> {}
export class CmWashRes extends RzRes<CmoWash> {}

/* Car ************* */
export class CmCarFindReq extends RzFindReq<CmoCar> {}
export class CmCarRsRes extends RzRsRes<CmoCar> {}
export class CmCarReq extends RzReq<CmoCar> {}
export class CmCarRes extends RzRes<CmoCar> {}

/* CarAddOn ************* */
export class CmCarAddOnFindReq extends RzFindReq<CmoCarAddOn>{}
export class CmCarAddOnRsRes extends RzRsRes<CmoCarAddOn>{}
export class CmCarAddOnReq extends RzReq<CmoCarAddOn> {}
export class CmCarAddOnRes  extends RzRes<CmoCarAddOn> {}

/* Rsv ************* */
export class CmRsvFindReq extends RzFindReq<CmoRsv> {}
export class CmRsvRsRes extends RzRsRes<CmoRsv> {}
export class CmRsvReq extends RzReq<CmoRsv> {}
export class CmRsvRes extends RzRes<CmoRsv> {}

/* Pay ************* */
export class CmPayFindReq extends RzFindReq<CmoPay> {}
export class CmPayRsRes extends RzRsRes<CmoPay> {}
export class CmPayReq extends RzReq<CmoPay> {}
export class CmPayRes extends RzRes<CmoPay> {}

/* Cash ************* */
export class CmCashFindReq extends RzFindReq<CmoPrepaidCash> {}
export class CmCashRsRes extends RzRsRes<CmoPrepaidCash> {}
export class CmCashReq extends RzReq<CmoPrepaidCash> {}
export class CmCashRes extends RzRes<CmoPrepaidCash> {}



/* CreditCard ********* */
export class CmCreditCardFindReq extends RzFindReq<CmoCreditCard> {}
export class CmCreditCardRsRes extends RzRsRes<CmoCreditCard> {}
export class CmCreditCardReq extends RzReq<CmoCreditCard> {}
export class CmCreditCardRes extends RzRes<CmoCreditCard> {}

/* Cpn ******************* */
export class CmCpnFindReq extends RzFindReq<CmoCpn> {}
export class CmCpnRsRes extends RzRsRes<CmoCpn> {}
export class CmCpnReq extends RzReq<CmoCpn> {}
export class CmCpnRes extends RzRes<CmoCpn> {}
export class CmCpnModelFindReq extends RzFindReq<CmoCpnModel> {}
export class CmCpnModelRsRes extends RzRsRes<CmoCpnModel> {}
export class CmCpnModelReq extends RzReq<CmoCpnModel> {}
export class CmCpnModelRes extends RzRes<CmoCpnModel> {}

/* Gift ******************* */
export class CmGiftFindReq extends RzFindReq<CmoGift> {}
export class CmGiftRsRes extends RzRsRes<CmoGift> {}
export class CmGiftReq extends RzReq<CmoGift> {}
export class CmGiftRes extends RzRes<CmoGift> {}
export class CmGiftModelFindReq extends RzFindReq<CmoGiftModel> {}
export class CmGiftModelRsRes extends RzRsRes<CmoGiftModel> {}
export class CmGiftModelReq extends RzReq<CmoGiftModel> {}
export class CmGiftModelRes extends RzRes<CmoGiftModel> {}

/************** */

export class CmWashPodFindReq extends RzFindReq<CmoWashPod> {}
export class CmWashPodRsRes extends RzRsRes<CmoWashPod> {}
export class CmWashPodReq extends RzReq<CmoWashPod> {}
export class CmWashPodMutReq extends RzReq<CmoWashPod> {}
export class CmWashPodRes extends RzRes<CmoWashPod> {}

/**************** */
export class CmShopReq extends RzReq<CmoShop> {}
export class CmShopMutReq extends RzMutReq<CmoShop> {}
export class CmShopRes extends RzRes<CmoShop> {}
export class CmShopFindReq extends RzFindReq<CmoShop> {}
export class CmShopRsRes extends RzRsRes<CmoShop> {}
export class CmReviewFindReq extends RzFindReq<CmoReview>{}
export class CmShopEquipFindReq extends RzFindReq<CmoShopEquipIn>{}
export class CmShopEquipMutReq extends RzReq<CmoShopEquipIn> {}
export class CmReviewRsRes extends RzRsRes<CmoReview>{}
export class CmShopEquipRes extends RzRsRes<CmoShopEquip>{}
export class CmShopInfoMutReq extends RzReq<CmoShopInfo> {}
export class CmShopInfoRes extends RzRsRes<CmoShopInfo> {}


/**************** */
export class CmLocFindReq extends RzFindReq<CmoLoc> {}
export class CmLocRsRes extends RzRsRes<CmoLoc> {}
/**************** */
export class CmWashCourseFindReq extends RzFindReq<CmoWashCourse> {}
export class CmWashCourseRsRes extends RzRsRes<CmoWashCourse> {}
export class CmWashCourseReq extends RzReq<CmoWashCourse> {}
export class CmWashCourseRes extends RzRes<CmoWashCourse> {}
/**************** */
export class CmWashOptFindReq extends RzFindReq<CmoWashOpt> {}
export class CmWashOptRsRes extends RzRsRes<CmoWashOpt> {}
export class CmWashOptReq extends RzReq<CmoWashOpt> {}
export class CmWashOptRes extends RzRes<CmoWashOpt> {}
/**************** */
export class CmStatFindReq extends RzFindReq<CmoStat> {}
export class CmStatRsRes extends RzRsRes<CmoStat> {}
export class CmStatReq extends RzReq<CmoStat> {}
export class CmStatRes extends RzRes<CmoStat> {}

/**************** */
export class CmSettleFindReq extends RzFindReq<CmoSettle> {}
export class CmSettleRsRes extends RzRsRes<CmoSettle> {}
export class CmSettleReq extends RzReq<CmoSettle> {}
export class CmSettleRes extends RzRes<CmoSettle> {}
export class CmSettleRowFindReq extends RzFindReq<CmoSettleRow> {}
export class CmSettleRowRsRes extends RzRsRes<CmoSettleRow> {}
export class CmSettleRowReq extends RzReq<CmoSettleRow> {}
export class CmSettleRowRes extends RzRes<CmoSettleRow> {}
