import {RzBasUiRepo, RzClient, rzClient_get} from '../rzcmnui';
import {NewOk, RzBoolRes, RzRes, rzlog} from '../rzcmn/';

import {
  LoginRes,
  LoginReq,
  AuthSmsReq,
  AuthSmsRes,
  LogoutReq,
  LogoutRes,
  UserDupReq,
  UserRegReq,
  UserRes,
  BbsArticleFindReq,
  BbsArticleRsRes,
  CmWashPodFindReq,
  CmWashPodRsRes,
  CmWashPodRes,
  CmWashPodReq,
  CmWashPodMutReq,
  BbsArticleReq,
  CmShopEquipFindReq,
  CmShopEquipMutReq,
  CmShopEquipRes,
 
  BbsArticleRes,
  BbsReplyFindReq,
  BbsReplyMutReq,
  BbsReplyReq,
  BbsReplyRes,
  BbsReplyRsRes,
  UserReq,
  WeatherReq,
  WeatherRes,
  CmWashFindReq,
  CmWashRsRes,
  CmWashRes,
  CmWashReq,
  CmShopFindReq,
  CmShopReq,
  CmShopRsRes,
  CmShopRes,
  CmCpnFindReq,
  CmCpnModelFindReq,
  CmCpnModelReq,
  CmCpnModelRes,
  CmCpnModelRsRes,
  CmCpnReq,
  CmCpnRes,
  CmCpnRsRes,
  CmGiftFindReq,
  CmGiftModelFindReq,
  CmGiftModelReq,
  CmGiftModelRes,
  CmGiftModelRsRes,
  CmGiftReq,
  CmGiftRes,
  CmGiftRsRes,
  CmPayFindReq,
  CmPayReq,
  CmPayRes,
  CmPayRsRes,
  CmRsvFindReq,
  CmRsvReq,
  CmRsvRes,
  CmRsvRsRes,
  CmCarFindReq,
  CmCarRsRes,
  CmCarReq,
  CmCarRes,
  WeatherRsRes,
  WeatherFindReq,
  CmNotiReq,
  CmNotiRes,
  CmNotiFindReq,
  CmNotiRsRes,
  CmCreditCardReq,
  CmCreditCardRes,
  CmCreditCardFindReq,
  CmCreditCardRsRes,
  CmLocFindReq,
  CmLocRsRes,
  CmCarAddOnFindReq,
  CmCarAddOnRsRes,
  BbsFavorReq,
  BbsFavorRes,
  CmReviewFindReq,
  CmReviewRsRes,
  CmWashCourseFindReq,
  CmWashCourseRsRes,
  CmWashCourseRes,
  CmWashCourseReq,
  CmWashOptFindReq,
  CmWashOptRsRes,
  CmWashOptRes,
  CmWashOptReq,
  CmSettleFindReq,
  CmSettleRsRes,
  CmSettleRes,
  CmSettleReq,
  CmSettleRowFindReq,
  CmSettleRowRsRes,
  CmSettleRowRes,
  CmSettleRowReq,
  CmCashFindReq,
  CmCashRsRes,
  CmCashRes,
  CmCashReq,
  CmStatFindReq,
  CmStatReq,
  CmStatRes,
  CmStatRsRes,
  CmShopInfoMutReq,
  CmShopInfoRes,
  FileRefReq,
  FileRefRes,
  RzFileReq,
  RzFileRes,
  BbsAttachReq,
  BbsAttachRes
} from './msg';
import {CmRepo} from './cmrepo';
import {
  auth_login,
  auth_loginByToken,
  auth_logout,
  bbs_addArticle,
  bbs_addReply,
  bbs_delArticle,
  bbs_delReply,
  bbs_findArticle,
  bbs_findReply,
  bbs_getArticle,
  bbs_getReply,
  bbs_putArticle,
  bbs_putFavor,
  bbs_putLike,
  bbs_putReply,
  cmCpn_addCpn,
  cmCpn_addCpnModel,
  cmCpn_addGift,
  cmCpn_addGiftModel,
  cmCpn_delCpn,
  cmCpn_delCpnModel,
  cmCpn_delGift,
  cmCpn_delGiftModel,
  cmCpn_findCpn,
  cmCpn_findCpnModel,
  cmCpn_findGift,
  cmCpn_findGiftModel,
  cmCpn_getCpn,
  cmCpn_getCpnModel,
  cmCpn_getGift,
  cmCpn_getGiftModel,
  cmCpn_putCpn,
  cmCpn_putCpnModel,
  cmCpn_putGift,
  cmCpn_putGiftModel,
  cmPay_addCreditCard,
  cmPay_addPay,
  cmPay_delCreditCard,
  cmPay_delPay,
  cmPay_findCreditCard,
  cmPay_findPay,
  cmPay_getCreditCard,
  cmPay_putCreditCard,
  cmPay_putPay,
  cmRsv_addRsv,
  cmRsv_delRsv,
  cmRsv_findRsv,
  cmRsv_getRsv,
  cmRsv_putRsv,
  cmShop_addShop,
  cmShop_addWashPod,
  cmShop_delShop,
  cmShop_putShopInfo,
  cmShop_delWashPod,
  cmShop_findShop,
  cmShop_findWashPod,
  cmShop_getShop,
  cmShop_putShop,
  
  cmShop_putWashPod,
  cmUser_addCar,
  cmUser_delCar,
  cmUser_findCar,
  cmUser_getCar,
  cmUser_getUser,
  cmUser_findUser,
  cmUser_putCar,
  cmUser_addUser,
  cmUser_putUser,
  cmUser_putUserPwd,
  cmUser_regUser,
  cmn_findWeather,
  cmn_getWeather,
  noti_addNoti,
  noti_delNoti,
  noti_findNoti,
  noti_getNoti,
  noti_putNoti,
  bbs_addFavor,
  cmShop_findReview,
  cmShop_findShopEquip,
  cmShop_addShopEquip,
  cmShop_putShopEquip,
  cmShop_delShopEquip,
  cmWash_delWashOpt,
  cmWash_putWashOpt,
  cmWash_addWashOpt,
  cmWash_getWashOpt,
  cmWash_findWashOpt,
  cmWash_delWashCourse,
  cmWash_findWashCourse,
  cmWash_getWashCourse,
  cmWash_addWashCourse,
  cmWash_putWashCourse,
  cmWash_findWash,
  cmWash_getWash,
  cmWash_addWash,
  cmWash_putWash,
  cmWash_delWash,
  cmPay_getPay,
  cmPay_findSettle,
  cmPay_getSettle,
  cmPay_addSettle,
  cmPay_putSettle,
  cmPay_delSettle,
  cmPay_findSettleRow,
  cmPay_getSettleRow,
  cmPay_addSettleRow,
  cmPay_putSettleRow,
  cmPay_delSettleRow,
  cmPay_findCash,
  cmPay_getCash,
  cmPay_addCash,
  cmPay_putCash,
  cmPay_delCash,
  cmShop_getWashPod,
  cmStat_findStat,
  cmStat_getStat,
  cmStat_addStat,
  cmStat_putStat,
  cmStat_delStat,
  cmShop_addShopInfo,
  cmUser_delUser,
  pds_putFile,
  pds_delFile,
  noti_sendNoti,
  bbs_addAttach,
  bbs_putAttach,
  bbs_delAttach,
  cmShop_findArticle
} from './cmrepo.rmt.gql';
import {CmoCarAddOn, CmoFileRef, CmoLoc} from './dto';

const rzIs = rzlog.makeDefs();
/**
 *
 */
export class CmRepo4Rmt extends RzBasUiRepo implements CmRepo {
  client: RzClient;
  constructor(conf?: any) {
    super();
    if (rzIs.i) rzlog.info('CmRepo4Rmt - CONF : ', conf);

    this.client = rzClient_get(conf, this.ctx);
  }

  async uploadFile(
    fileObj: any,
    isPrivate?: boolean,
    uri?: string,
  ): Promise<RzRes<CmoFileRef>> {
    let r = await this.client.uploadFile(fileObj, isPrivate, uri);
    return r;
  }

  async putFile(req: RzFileReq):Promise<RzFileRes>{
    let r = await this.client.mutate(pds_putFile, req, 'pds_putFile');
    return r;
  }
  
  async delFile(req: RzFileReq):Promise<RzFileRes>{
    let r = await this.client.mutate(pds_delFile, req, 'pds_delFile');
    return r;
  }

  /****************************
   *  CarAddOn
   */
  async findCarAddOn(rq: CmCarAddOnFindReq): Promise<CmCarAddOnRsRes> {
    let dt = [] as CmoCarAddOn[];
    return NewOk(dt);
  }

  /****************************
   *  User
   */
  async getUser(rq: UserReq): Promise<UserRes> {
    let r = await this.client.query(cmUser_getUser, rq, 'cmUser_getUser');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getUser : dt=', dt);
    return r;
  }
  async findUser(rq: UserReq): Promise<UserRes> {
    let r = await this.client.query(cmUser_findUser, rq, 'cmUser_findUser');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findUser : dt=', dt);
    return r;
  }
  async putUser(rq: UserReq): Promise<UserRes> {
    let r = await this.client.mutate(cmUser_putUser, rq, 'cmUser_putUser');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putUser : dt=', dt);
    return r;
  }
  async delUser(rq: UserReq): Promise<UserRes> {
    let r = await this.client.mutate(cmUser_delUser, rq, 'cmUser_delUser');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delUser : dt=', dt);
    return r;
  }
  async addUser(rq: UserReq): Promise<UserRes> {
    let r = await this.client.mutate(cmUser_addUser, rq, 'cmUser_addUser');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addUser : dt=', dt);
    return r;
  }
  async putUserPwd(rq: UserReq): Promise<UserRes> {
    let r = await this.client.mutate(cmUser_putUserPwd, rq, 'cmUser_putUserPwd');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putUserPwd : dt=', dt);
    return r;
  }

  clearTokens(): Promise<RzBoolRes> {
    throw new Error('Method not implemented.');
  }
  /****************************/

  async findCar(rq: CmCarFindReq): Promise<CmCarRsRes> {
    let r = await this.client.query(cmUser_findCar, rq, 'cmUser_findCar');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findCar : dt=', dt);
    return r;
  }

  async getCar(rq: CmCarReq): Promise<CmCarRes> {
    let r = await this.client.query(cmUser_getCar, rq, 'cmUser_getCar');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getCar : dt=', dt);
    return r;
  }
  async addCar(rq: CmCarReq): Promise<CmCarRes> {
    let r = await this.client.mutate(cmUser_addCar, rq, 'cmUser_addCar');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addCar : dt=', dt);
    return r;
  }
  async putCar(rq: CmCarReq): Promise<CmCarRes> {
    let r = await this.client.mutate(cmUser_putCar, rq, 'cmUser_putCar');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putCar : dt=', dt);
    return r;
  }
  async delCar(rq: CmCarReq): Promise<CmCarRes> {
    let r = await this.client.mutate(cmUser_delCar, rq, 'cmUser_delCar');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delCar : dt=', dt);
    return r;
  }
  /*****************
   *
   */
  async findCreditCard(rq: CmCreditCardFindReq): Promise<CmCreditCardRsRes> {
    let r = await this.client.query(
      cmPay_findCreditCard,
      rq,
      'cmPay_findCreditCard',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findCreditCard : dt=', dt);
    return r;
  }

  async getCreditCard(rq: CmCreditCardReq): Promise<CmCreditCardRes> {
    let r = await this.client.query(
      cmPay_getCreditCard,
      rq,
      'cmPay_getCreditCard',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getCreditCard : dt=', dt);
    return r;
  }

  async addCreditCard(rq: CmCreditCardReq): Promise<CmCreditCardRes> {
    let r = await this.client.mutate(
      cmPay_addCreditCard,
      rq,
      'cmPay_addCreditCard',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addCreditCard : dt=', dt);
    return r;
  }
  async putCreditCard(rq: CmCreditCardReq): Promise<CmCreditCardRes> {
    let r = await this.client.mutate(
      cmPay_putCreditCard,
      rq,
      'cmPay_putCreditCard',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putCreditCard : dt=', dt);
    return r;
  }
  async delCreditCard(rq: CmCreditCardReq): Promise<CmCreditCardRes> {
    let r = await this.client.mutate(
      cmPay_delCreditCard,
      rq,
      'cmPay_delCreditCard',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delCreditCard : dt=', dt);
    return r;
  }

  /****************************
   *
   */

  async getWeather(rq: WeatherReq): Promise<WeatherRes> {
    let r = await this.client.query(cmn_getWeather, rq, 'cmn_getWeather');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getWeather : dt=', dt);
    return r;
  }
  async findWeather(rq: WeatherFindReq): Promise<WeatherRsRes> {
    let r = await this.client.query(cmn_findWeather, rq, 'cmn_findWeather');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findWeather : dt=', dt);
    return r;
  }
  /****************************
   *  BbsReply
   */
  async findBbsReply(rq: BbsReplyFindReq): Promise<BbsReplyRsRes> {
    let r = await this.client.query(bbs_findReply, rq, 'bbs_findReply');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findBbsReply : dt=', dt);
    return r;
  }

  async getBbsReply(rq: BbsReplyReq): Promise<BbsReplyRes> {
    let r = await this.client.query(bbs_getReply, rq, 'bbs_getReply');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getBbsReply : dt=', dt);
    return r;
  }

  async addBbsReply(rq: BbsReplyMutReq): Promise<BbsReplyRes> {
    let r = await this.client.mutate(bbs_addReply, rq, 'bbs_addReply');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addBbsReply : dt=', dt);
    return r;
  }
  async putBbsReply(rq: BbsReplyMutReq): Promise<BbsReplyRes> {
    let r = await this.client.mutate(bbs_putReply, rq, 'bbs_putReply');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putBbsReply : dt=', dt);
    return r;
  }
  async delBbsReply(rq: BbsReplyMutReq): Promise<BbsReplyRes> {
    let r = await this.client.mutate(bbs_delReply, rq, 'bbs_delReply');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delBbsReply : dt=', dt);
    return r;
  }

  /****************************
   *  Noti
   */
  async findNoti(rq: CmNotiFindReq): Promise<CmNotiRsRes> {
    let r = await this.client.query(noti_findNoti, rq, 'noti_findNoti');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findNoti : dt=', dt);
    return r;
  }

  async getNoti(rq: CmNotiReq): Promise<CmNotiRes> {
    let r = await this.client.query(noti_getNoti, rq, 'noti_getNoti');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getNoti : dt=', dt);
    return r;
  }

  async addNoti(rq: CmNotiReq): Promise<CmNotiRes> {
    let r = await this.client.mutate(noti_addNoti, rq, 'noti_addNoti');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addNoti : dt=', dt);
    return r;
  }
  async sendNoti(rq: CmNotiReq): Promise<CmNotiRes> {
    let r = await this.client.mutate(noti_sendNoti, rq, 'noti_sendNoti');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addNoti : dt=', dt);
    return r;
  }
  async putNoti(rq: CmNotiReq): Promise<CmNotiRes> {
    let r = await this.client.mutate(noti_putNoti, rq, 'noti_putNoti');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putNoti : dt=', dt);
    return r;
  }
  async delNoti(rq: CmNotiReq): Promise<CmNotiRes> {
    let r = await this.client.mutate(noti_delNoti, rq, 'noti_delNoti');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delNoti : dt=', dt);
    return r;
  }

  /****************************
   *  BbsArticle
   */
  async findBbsArticle(rq: BbsArticleFindReq): Promise<BbsArticleRsRes> {
    let r = await this.client.query(bbs_findArticle, rq, 'bbs_findArticle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findArticle : dt=', dt);
    return r;
  }

  async getBbsArticle(rq: BbsArticleReq): Promise<BbsArticleRes> {
    let r = await this.client.query(bbs_getArticle, rq, 'bbs_getArticle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getBbsArticle : dt=', dt);
    return r;
  }
  async addBbsArticle(rq: BbsArticleReq): Promise<BbsArticleRes> {
    let r = await this.client.mutate(bbs_addArticle, rq, 'bbs_addArticle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addBbsArticle : dt=', dt);
    return r;
  }
  async putBbsArticle(rq: BbsArticleReq): Promise<BbsArticleRes> {
    let r = await this.client.mutate(bbs_putArticle, rq, 'bbs_putArticle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putBbsArticle : dt=', dt);
    return r;
  }

  async putBbsLike(rq: BbsArticleReq): Promise<BbsArticleRes> {
    let r = await this.client.mutate(bbs_putLike, rq, 'bbs_putLike');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.bbs_putLike : dt=', dt);
    return r;
  }
  async putBbsFavor(rq: BbsFavorReq): Promise<BbsFavorRes> {
    let r = await this.client.mutate(bbs_putFavor, rq, 'bbs_putFavor');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.bbs_putFavor : dt=', dt);
    return r;
  }
  async addFavor(rq: BbsFavorReq): Promise<BbsFavorRes> {
    let r = await this.client.mutate(bbs_addFavor, rq, 'bbs_addFavor');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('bbs_addFavor : dt=', dt);
    return r;
  }

  async delBbsArticle(rq: BbsArticleReq): Promise<BbsArticleRes> {
    let r = await this.client.mutate(bbs_delArticle, rq, 'bbs_delArticle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delBbsArticle : dt=', dt);
    return r;
  }

  async addAttach(rq: BbsAttachReq): Promise<BbsAttachRes> {
    let r = await this.client.mutate(bbs_addAttach, rq, 'bbs_addAttach');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addAttach : dt=', dt);
    return r;
  }
  async putAttach(rq: BbsAttachReq): Promise<BbsAttachRes> {
    let r = await this.client.mutate(bbs_putAttach, rq, 'bbs_putAttach');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putAttach : dt=', dt);
    return r;
  }
  async delAttach(rq: BbsAttachReq): Promise<BbsAttachRes> {
    let r = await this.client.mutate(bbs_delAttach, rq, 'bbs_delAttach');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delAttach : dt=', dt);
    return r;
  }

  /****************************
   *
   */

  async sendAuthSmsCode(req: AuthSmsReq): Promise<RzBoolRes> {
    throw new Error('Method not implemented.');
  }

  async loginByToken(rq: LoginReq): Promise<LoginRes> {
    let r = await this.client.mutate(
      auth_loginByToken,
      rq,
      'auth_loginByToken',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.loginByToken : dt=', dt);
    return r;
  }

  hasToken(): boolean {
    return true;
  }
  /****************************
   *
   */
  async login(rq: LoginReq): Promise<LoginRes> {
    let r = await this.client.mutate(auth_login, rq, 'auth_login');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.login : dt=', dt);
    return r;
  }
  async logout(rq: LogoutReq): Promise<LogoutRes> {
    let r = await this.client.mutate(auth_logout, rq, 'auth_logout');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.logout : dt=', dt);
    return r;
  }
  async sendAuthSms(req: AuthSmsReq): Promise<AuthSmsRes> {
    throw new Error('Method not implemented.');
  }
  async reqUserDup(req: UserDupReq): Promise<RzBoolRes> {
    throw new Error('Method not implemented.');
  }
  async regUser(rq: UserRegReq): Promise<UserRes> {
    let r = await this.client.mutate(cmUser_regUser, rq, 'cmUser_regUser');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.regUser : dt=', dt);
    return r;
  }

  async signin(rq: LoginReq): Promise<LoginRes> {
    let r = new LoginRes();
    return r;
  }
  /**************** */
  async findRsv(rq: CmRsvFindReq): Promise<CmRsvRsRes> {
    let r = await this.client.query(cmRsv_findRsv, rq, 'cmRsv_findRsv');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findRsv : dt=', dt);
    return r;
  }
  async getRsv(rq: CmRsvReq): Promise<CmRsvRes> {
    let r = await this.client.query(cmRsv_getRsv, rq, 'cmRsv_getRsv');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getRsv : dt=', dt);
    return r;
  }
  async addRsv(rq: CmRsvReq): Promise<CmRsvRes> {
    let r = await this.client.mutate(cmRsv_addRsv, rq, 'cmRsv_addRsv');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addRsv : dt=', dt);
    return r;
  }
  async putRsv(rq: CmRsvReq): Promise<CmRsvRes> {
    let r = await this.client.mutate(cmRsv_putRsv, rq, 'cmRsv_putRsv');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putRsv : dt=', dt);
    return r;
  }
  async delRsv(rq: CmRsvReq): Promise<CmRsvRes> {
    let r = await this.client.mutate(cmRsv_delRsv, rq, 'cmRsv_delRsv');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delRsv : dt=', dt);
    return r;
  }
  /* Pay *************/
  async findPay(rq: CmPayFindReq): Promise<CmPayRsRes> {
    let r = await this.client.query(cmPay_findPay, rq, 'cmPay_findPay');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findPay : dt=', dt);
    return r;
  }
  async getPay(rq: CmPayReq): Promise<CmPayRes> {
    let r = await this.client.query(cmPay_getPay, rq, 'cmPay_getPay');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getWash : dt=', dt);
    return r;
  }
  async addPay(rq: CmPayReq): Promise<CmPayRes> {
    let r = await this.client.mutate(cmPay_addPay, rq, 'cmPay_addPay');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addTask : dt=', dt);
    return r;
  }
  async putPay(rq: CmPayReq): Promise<CmPayRes> {
    let r = await this.client.mutate(cmPay_putPay, rq, 'cmPay_putPay');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putPay : dt=', dt);
    return r;
  }
  async delPay(rq: CmPayReq): Promise<CmPayRes> {
    let r = await this.client.mutate(cmPay_delPay, rq, 'cmPay_delPay');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delPay : dt=', dt);
    return r;
  }
  /* Cash *************/
  async findCash(rq: CmCashFindReq): Promise<CmCashRsRes> {
    let r = await this.client.query(cmPay_findCash, rq, 'cmPay_findCash');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findCash : dt=', dt);
    return r;
  }
  async getCash(rq: CmCashReq): Promise<CmCashRes> {
    let r = await this.client.query(cmPay_getCash, rq, 'cmPay_getCash');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getCash : dt=', dt);
    return r;
  }
  async addCash(rq: CmCashReq): Promise<CmCashRes> {
    let r = await this.client.mutate(cmPay_addCash, rq, 'cmPay_addCash');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addCash : dt=', dt);
    return r;
  }
  async putCash(rq: CmCashReq): Promise<CmCashRes> {
    let r = await this.client.mutate(cmPay_putCash, rq, 'cmPay_putCash');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putCash : dt=', dt);
    return r;
  }
  async delCash(rq: CmCashReq): Promise<CmCashRes> {
    let r = await this.client.mutate(cmPay_delCash, rq, 'cmPay_delCash');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delCash : dt=', dt);
    return r;
  }  


  /* CPN ***********/
  async findCpn(rq: CmCpnFindReq): Promise<CmCpnRsRes> {
    let r = await this.client.query(cmCpn_findCpn, rq, 'cmCpn_findCpn');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findCpn : dt=', dt);
    return r;
  }
  async getCpn(rq: CmCpnReq): Promise<CmCpnRes> {
    let r = await this.client.query(cmCpn_getCpn, rq, 'cmCpn_getCpn');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getCpn : dt=', dt);
    return r;
  }
  async addCpn(rq: CmCpnReq): Promise<CmCpnRes> {
    let r = await this.client.mutate(cmCpn_addCpn, rq, 'cmCpn_addCpn');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addCpn : dt=', dt);
    return r;
  }
  async putCpn(rq: CmCpnReq): Promise<CmCpnRes> {
    let r = await this.client.mutate(cmCpn_putCpn, rq, 'cmCpn_putShop');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addTask : dt=', dt);
    return r;
  }
  async delCpn(rq: CmCpnReq): Promise<CmCpnRes> {
    let r = await this.client.mutate(cmCpn_delCpn, rq, 'cmCpn_delCpn');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delCpn : dt=', dt);
    return r;
  }
  /* CpnModel ************/
  async findCpnModel(rq: CmCpnModelFindReq): Promise<CmCpnModelRsRes> {
    let r = await this.client.query(
      cmCpn_findCpnModel,
      rq,
      'cmCpn_findCpnModel',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findCpnModel : dt=', dt);
    return r;
  }
  async getCpnModel(rq: CmCpnModelReq): Promise<CmCpnModelRes> {
    let r = await this.client.query(cmCpn_getCpnModel, rq, 'cmCpn_getCpnModel');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getWash : dt=', dt);
    return r;
  }
  async addCpnModel(rq: CmCpnModelReq): Promise<CmCpnModelRes> {
    let r = await this.client.mutate(
      cmCpn_addCpnModel,
      rq,
      'cmCpn_addCpnModel',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addCpnModel : dt=', dt);
    return r;
  }
  async putCpnModel(rq: CmCpnModelReq): Promise<CmCpnModelRes> {
    let r = await this.client.mutate(
      cmCpn_putCpnModel,
      rq,
      'cmCpn_putCpnModel',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putCpnModel : dt=', dt);
    return r;
  }
  async delCpnModel(rq: CmCpnModelReq): Promise<CmCpnModelRes> {
    let r = await this.client.mutate(
      cmCpn_delCpnModel,
      rq,
      'cmCpn_delCpnModel',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delCpnModel : dt=', dt);
    return r;
  }
  /* GIFT ****************** */
  async findGift(rq: CmGiftFindReq): Promise<CmGiftRsRes> {
    let r = await this.client.query(cmCpn_findGift, rq, 'cmCpn_findGift');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findGift : dt=', dt);
    return r;
  }
  async getGift(rq: CmGiftReq): Promise<CmGiftRes> {
    let r = await this.client.query(cmCpn_getGift, rq, 'cmCpn_getGift');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getWash : dt=', dt);
    return r;
  }
  async addGift(rq: CmGiftReq): Promise<CmGiftRes> {
    let r = await this.client.mutate(cmCpn_addGift, rq, 'cmCpn_addGift');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addGift : dt=', dt);
    return r;
  }
  async putGift(rq: CmGiftReq): Promise<CmGiftRes> {
    let r = await this.client.mutate(cmCpn_putGift, rq, 'cmCpn_putGift');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putGift : dt=', dt);
    return r;
  }
  async delGift(rq: CmGiftReq): Promise<CmGiftRes> {
    let r = await this.client.mutate(cmCpn_delGift, rq, 'cmCpn_delGift');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delGift : dt=', dt);
    return r;
  }

  /* GIFT MODEL ******** */
  async findGiftModel(rq: CmGiftModelFindReq): Promise<CmGiftModelRsRes> {
    let r = await this.client.query(
      cmCpn_findGiftModel,
      rq,
      'cmCpn_findGiftModel',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findGiftModel : dt=', dt);
    return r;
  }
  async getGiftModel(rq: CmGiftModelReq): Promise<CmGiftModelRes> {
    let r = await this.client.query(
      cmCpn_getGiftModel,
      rq,
      'cmCpn_getGiftModel',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getGiftModel : dt=', dt);
    return r;
  }
  async addGiftModel(rq: CmGiftModelReq): Promise<CmGiftModelRes> {
    let r = await this.client.mutate(
      cmCpn_addGiftModel,
      rq,
      'cmCpn_addGiftModel',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addGiftModel : dt=', dt);
    return r;
  }
  async putGiftModel(rq: CmGiftModelReq): Promise<CmGiftModelRes> {
    let r = await this.client.mutate(
      cmCpn_putGiftModel,
      rq,
      'cmCpn_putGiftModel',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putGiftModel : dt=', dt);
    return r;
  }
  async delGiftModel(rq: CmGiftModelReq): Promise<CmGiftModelRes> {
    let r = await this.client.mutate(
      cmCpn_delGiftModel,
      rq,
      'cmCpn_delGiftModel',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delGiftModel : dt=', dt);
    return r;
  }

  /* Loc *********** */
  async findLoc(rq: CmLocFindReq): Promise<CmLocRsRes> {
    let dt = [
      {id: '1', title: '홍대'},
      {id: '2', title: '강남'},
      {id: '3', title: '종로'},
    ] as CmoLoc[];
    return NewOk(dt);
  }

  /* SHOP *********** */
  async findShop(rq: CmShopFindReq): Promise<CmShopRsRes> {
    let r = await this.client.query(cmShop_findShop, rq, 'cmShop_findShop');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findShop : dt=', dt);
    return r;
  }
  async getShop(rq: CmShopReq): Promise<CmShopRes> {
    let r = await this.client.query(cmShop_getShop, rq, 'cmShop_getShop');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getShop : dt=', dt);
    return r;
  }
  async addShop(rq: CmShopReq): Promise<CmShopRes> {
    let r = await this.client.mutate(cmShop_addShop, rq, 'cmShop_addShop');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addTask : dt=', dt);
    return r;
  }
  async putShop(rq: CmShopReq): Promise<CmShopRes> {
    let r = await this.client.mutate(cmShop_putShop, rq, 'cmShop_putShop');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putTask : dt=', dt);
    return r;
  }
  async delShop(rq: CmShopReq): Promise<CmShopRes> {
    let r = await this.client.mutate(cmShop_delShop, rq, 'cmShop_delShop');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delTask : dt=', dt);
    return r;
  }

  async addShopInfo(rq: CmShopInfoMutReq): Promise<CmShopInfoRes> {
    let r = await this.client.mutate(cmShop_addShopInfo, rq, 'cmShop_addShopInfo');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delTask : dt=', dt);
    return r;
  }

  async putShopInfo(rq: CmShopInfoMutReq): Promise<CmShopInfoRes> {
    let r = await this.client.mutate(cmShop_putShopInfo, rq, 'cmShop_putShopInfo');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delTask : dt=', dt);
    return r;
  }
  async findReview(rq: CmReviewFindReq): Promise<CmReviewRsRes> {
    let r = await this.client.query(cmShop_findReview, rq, 'cmShop_findReview');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findShop : dt=', dt);
    return r;
  }

  async findArticle(rq: BbsArticleFindReq): Promise<BbsArticleRsRes> {
    let r = await this.client.query(cmShop_findArticle, rq, 'cmShop_findArticle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findArticle : dt=', dt);
    return r;
  }


  async findShopEquip(rq: CmShopEquipFindReq): Promise<CmShopEquipRes> {
    let r = await this.client.query(cmShop_findShopEquip, rq, 'cmShop_findShopEquip');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.cmShop_findShopEquip : dt=', dt);
    return r;
  }
  async addShopEquip(rq: CmShopEquipMutReq): Promise<CmShopEquipRes> {    
    let r = await this.client.mutate(cmShop_addShopEquip, rq, 'cmShop_addShopEquip');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delTask : dt=', dt);
    return r;
  }
  async putShopEquip(rq: CmShopEquipMutReq): Promise<CmShopEquipRes> {    
    let r = await this.client.mutate(cmShop_putShopEquip, rq, 'cmShop_putShopEquip');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delTask : dt=', dt);
    return r;
  }
  async delShopEquip(rq: CmShopEquipMutReq): Promise<CmShopEquipRes> {    
    let r = await this.client.mutate(cmShop_delShopEquip, rq, 'cmShop_delShopEquip');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delTask : dt=', dt);
    return r;
  }

  /* WASHPOD *********  */
  async findWashPod(rq: CmWashPodFindReq): Promise<CmWashPodRsRes> {
    let r = await this.client.query(
      cmShop_findWashPod,
      rq,
      'cmShop_findWashPod',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findWashPod : dt=', dt);
    return r;
  }
  async getWashPod(rq: CmWashPodReq): Promise<CmWashPodRes> {
    let r = await this.client.query(cmShop_getWashPod, rq, 'cmShop_getWashPod');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getWash : dt=', dt);
    return r;
  }
  async addWashPod(rq: CmWashPodReq): Promise<CmWashPodRes> {
    let r = await this.client.mutate(
      cmShop_addWashPod,
      rq,
      'cmShop_addWashPod',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addWashPod : dt=', dt);
    return r;
  }
  async putWashPod(rq: CmWashPodReq): Promise<CmWashPodRes> {
    let r = await this.client.mutate(
      cmShop_putWashPod,
      rq,
      'cmShop_putWashPod',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putWashPod : dt=', dt);
    return r;
  }
  async delWashPod(rq: CmWashPodReq): Promise<CmWashPodRes> {
    let r = await this.client.mutate(
      cmShop_delWashPod,
      rq,
      'cmShop_delWashPod',
    );
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delWashPod : dt=', dt);
    return r;
  }

  /* Wash *********** */
  async findWash(rq: CmWashFindReq): Promise<CmWashRsRes> {
    let r = await this.client.query(cmWash_findWash, rq, 'cmWash_findWash');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findWash : dt=', dt);
    return r;
  }
  async getWash(rq: CmWashReq): Promise<CmWashRes> {
    let r = await this.client.query(cmWash_getWash, rq, 'cmWash_getWash');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getWash : dt=', dt);
    return r;
  }
  async addWash(rq: CmWashReq): Promise<CmWashRes> {
    let r = await this.client.mutate(cmWash_addWash, rq, 'cmWash_addWash');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addWash : dt=', dt);
    return r;
  }
  async putWash(rq: CmWashReq): Promise<CmWashRes> {
    let r = await this.client.mutate(cmWash_putWash, rq, 'cmWash_putWash');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putWash : dt=', dt);
    return r;
  }
  async delWash(rq: CmWashReq): Promise<CmWashRes> {
    let r = await this.client.mutate(cmWash_delWash, rq, 'cmWash_delWash');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delWash : dt=', dt);
    return r;
  }

 
   /* WashCourse *********** */
   async findWashCourse(rq: CmWashCourseFindReq): Promise<CmWashCourseRsRes> {
    let r = await this.client.query(cmWash_findWashCourse, rq, 'cmWash_findWashCourse');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findWashCourse : dt=', dt);
    return r;
  }
  async getWashCourse(rq: CmWashCourseReq): Promise<CmWashCourseRes> {
    let r = await this.client.query(cmWash_getWashCourse, rq, 'cmWash_getWashCourse');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getWashCourse : dt=', dt);
    return r;
  }
  async addWashCourse(rq: CmWashCourseReq): Promise<CmWashCourseRes> {
    let r = await this.client.mutate(cmWash_addWashCourse, rq, 'cmWash_addWashCourse');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addWashCourse : dt=', dt);
    return r;
  }
  async putWashCourse(rq: CmWashCourseReq): Promise<CmWashCourseRes> {
    let r = await this.client.mutate(cmWash_putWashCourse, rq, 'cmWash_putWashCourse');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putWashCourse : dt=', dt);
    return r;
  }
  async delWashCourse(rq: CmWashCourseReq): Promise<CmWashCourseRes> {
    let r = await this.client.mutate(cmWash_delWashCourse, rq, 'cmWash_delWashCourse');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delWashCourse : dt=', dt);
    return r;
  }

    /* WashOpt *********** */
    async findWashOpt(rq: CmWashOptFindReq): Promise<CmWashOptRsRes> {
      let r = await this.client.query(cmWash_findWashOpt, rq, 'cmWash_findWashOpt');
      let dt = r.data;
      if (rzIs.d) rzlog.debug('CmRepoRmt.findWashOpt : dt=', dt);
      return r;
    }
    async getWashOpt(rq: CmWashOptReq): Promise<CmWashOptRes> {
      let r = await this.client.query(cmWash_getWashOpt, rq, 'cmWash_getWashOpt');
      let dt = r.data;
      if (rzIs.d) rzlog.debug('CmRepoRmt.getWashOpt : dt=', dt);
      return r;
    }
    async addWashOpt(rq: CmWashOptReq): Promise<CmWashOptRes> {
      let r = await this.client.mutate(cmWash_addWashOpt, rq, 'cmWash_addWashOpt');
      let dt = r.data;
      if (rzIs.d) rzlog.debug('CmRepoRmt.addWashOpt : dt=', dt);
      return r;
    }
    async putWashOpt(rq: CmWashOptReq): Promise<CmWashOptRes> {
      let r = await this.client.mutate(cmWash_putWashOpt, rq, 'cmWash_putWashOpt');
      let dt = r.data;
      if (rzIs.d) rzlog.debug('CmRepoRmt.putWashOpt : dt=', dt);
      return r;
    }
    async delWashOpt(rq: CmWashOptReq): Promise<CmWashOptRes> {
      let r = await this.client.mutate(cmWash_delWashOpt, rq, 'cmWash_delWashOpt');
      let dt = r.data;
      if (rzIs.d) rzlog.debug('CmRepoRmt.delWashOpt : dt=', dt);
      return r;
    }
/* Stat *********** */
async findStat(rq: CmStatFindReq): Promise<CmStatRsRes> {
  let r = await this.client.query(cmStat_findStat, rq, 'cmStat_findStat');
  let dt = r.data;
  if (rzIs.d) rzlog.debug('CmRepoRmt.findStat : dt=', dt);
  return r;
}
async getStat(rq: CmStatReq): Promise<CmStatRes> {
  let r = await this.client.query(cmStat_getStat, rq, 'cmStat_getStat');
  let dt = r.data;
  if (rzIs.d) rzlog.debug('CmRepoRmt.getStat : dt=', dt);
  return r;
}
async addStat(rq: CmStatReq): Promise<CmStatRes> {
  let r = await this.client.mutate(cmStat_addStat, rq, 'cmStat_addStat');
  let dt = r.data;
  if (rzIs.d) rzlog.debug('CmRepoRmt.addStat : dt=', dt);
  return r;
}
async putStat(rq: CmStatReq): Promise<CmStatRes> {
  let r = await this.client.mutate(cmStat_putStat, rq, 'cmStat_putStat');
  let dt = r.data;
  if (rzIs.d) rzlog.debug('CmRepoRmt.putStat : dt=', dt);
  return r;
}
async delStat(rq: CmStatReq): Promise<CmStatRes> {
  let r = await this.client.mutate(cmStat_delStat, rq, 'cmStat_delStat');
  let dt = r.data;
  if (rzIs.d) rzlog.debug('CmRepoRmt.delStat : dt=', dt);
  return r;
}

   /* Settle *********** */
   async findSettle(rq: CmSettleFindReq): Promise<CmSettleRsRes> {
    let r = await this.client.query(cmPay_findSettle, rq, 'cmPay_findSettle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findSettle : dt=', dt);
    return r;
  }
  async getSettle(rq: CmSettleReq): Promise<CmSettleRes> {
    let r = await this.client.query(cmPay_getSettle, rq, 'cmPay_getSettle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getSettle : dt=', dt);
    return r;
  }
  async addSettle(rq: CmSettleReq): Promise<CmSettleRes> {
    let r = await this.client.mutate(cmPay_addSettle, rq, 'cmPay_addSettle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addSettle : dt=', dt);
    return r;
  }
  async putSettle(rq: CmSettleReq): Promise<CmSettleRes> {
    let r = await this.client.mutate(cmPay_putSettle, rq, 'cmPay_putSettle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putSettle : dt=', dt);
    return r;
  }
  async delSettle(rq: CmSettleReq): Promise<CmSettleRes> {
    let r = await this.client.mutate(cmPay_delSettle, rq, 'cmPay_delSettle');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delSettle : dt=', dt);
    return r;
  }
   /* SettleRow *********** */
   async findSettleRow(rq: CmSettleRowFindReq): Promise<CmSettleRowRsRes> {
    let r = await this.client.query(cmPay_findSettleRow, rq, 'cmPay_findSettleRow');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.findSettleRow : dt=', dt);
    return r;
  }
  async getSettleRow(rq: CmSettleRowReq): Promise<CmSettleRowRes> {
    let r = await this.client.query(cmPay_getSettleRow, rq, 'cmPay_getSettleRow');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.getSettleRow : dt=', dt);
    return r;
  }
  async addSettleRow(rq: CmSettleRowReq): Promise<CmSettleRowRes> {
    let r = await this.client.mutate(cmPay_addSettleRow, rq, 'cmPay_addSettleRow');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.addSettleRow : dt=', dt);
    return r;
  }
  async putSettleRow(rq: CmSettleRowReq): Promise<CmSettleRowRes> {
    let r = await this.client.mutate(cmPay_putSettleRow, rq, 'cmPay_putSettleRow');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.putSettleRow : dt=', dt);
    return r;
  }
  async delSettleRow(rq: CmSettleRowReq): Promise<CmSettleRowRes> {
    let r = await this.client.mutate(cmPay_delSettleRow, rq, 'cmPay_delSettleRow');
    let dt = r.data;
    if (rzIs.d) rzlog.debug('CmRepoRmt.delSettleRow : dt=', dt);
    return r;
  }
  
} //class
