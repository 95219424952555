import React, { Component, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { CmComponent } from '../contexts'
import { rzlog } from '../rzcmn'
 

 

export interface RequireAuthProp {
  children?: ReactNode
}

export class RequireAuth extends CmComponent<RequireAuthProp> {
  constructor(pr: any) {
    super(pr)
  }

  public render(): ReactNode {
    // let ctx=this.getCtx();
    // let ui=ctx?.userInfo;
    // rzlog.debug("AUTH ROUTE : ctx.ui=",ui);
    // if(!ui){
    //     return <Navigate to="/login" />
    // }
      
    let userInfo = this.getRepo().getAttr('userInfo');        
    if (!userInfo) {
      return <Navigate to="/login" />;
    }
    return this.props.children
  }
}
